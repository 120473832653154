import styled, {css} from "styled-components";

export const Marker = styled('div')(props => css`
    background: url(${props.theme.images.mapMarker}) no-repeat center center;
    background-size: cover;
    height: 30px;
    width: 20px;
    color: ${props.theme.colors.white};
    position: relative;
    overflow: hidden;
    margin-top: -30px;

    a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: background-color 250ms ease-in-out, color 250ms ease-in-out;
        font-size: 1px;
        text-indent: -999px;

        &:hover {
            background-color: rgba(255,255,255,0.1);
        }
    }
`);

export const InfoBox = styled('div')(props => css`
    width: 200px;
`);
