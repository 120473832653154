import styled, {css} from "styled-components";

import {Button as StyledButton} from "../../StyledComponents/Button";

export const Button = styled(StyledButton)(props => css`
    ${props.theme.fonts.regular};
    font-weight: bold;
    
    background-color: ${props.theme.colors.primary};
    color: ${props.theme.colors.white};
    padding: ${props.theme.space.sm} ${props.theme.space.md};
    border-radius: 0;
    display: flex;
    align-self: flex-end;
`);
