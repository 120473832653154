import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

import Page from "../Page";

import GoogleMaps from "../../Components/GoogleMaps";
import Accordion from "../../Components/Accordion";

import {Col, Row} from "../../StyledComponents/Grid";
import Block from "../../StyledComponents/Block";
import {Heading, Paragraph} from "../../StyledComponents/Typography";

import veterinarians from '../../Config/veterinarians';
import {locations} from "../../Config/locations";
import testimonials from "../../Config/testimonials";

const Locations = () => (
    <>
        <Helmet>
            <title>Dierenarts locaties - Mobieledierenscan.nl</title>
            <meta charSet="utf-8"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description"
                  content="CT-Scan laten maken bij uw eigen vertrouwde dierenarts. Wij zijn aangesloten bij de meeste dierenartsen in Nederland. Kijk of uw praktijk er tussen staan en maak een afspraak."/>
            <meta name="keywords"
                  content="Locaties, Dierenarts, Nederland, Eigen, Dichtbij, Reizen, CT-Scan, Huisdier, Kliniek"/>
            <link rel="canonical" href="https://mobieledierenscan.nl/diereneigenaar/locaties"/>
        </Helmet>

        <Page testimonials={testimonials}>
            <Row flexDirection={['column', 'column', 'column', 'row']}>
                <Col width={[1, 1, 1, 1 / 2]} pr={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <Block width={1} mb="md">
                        <Heading as="h1">Aangesloten praktijken</Heading>
                        <Paragraph>
                            Op deze pagina vindt u een overzicht van de aangesloten dierenartspraktijken die
                            gebruik maken van Veterinair Mobiele CT-scan. Staat uw eigen praktijk daar tussen,
                            dan kunt u contact opnemen met uw praktijk voor het inplannen van een afspraak.
                            Tevens kan uw praktijk u verder informeren omtrent onze service en kosten.
                        </Paragraph>
                        <Accordion>
                            {Object.keys(veterinarians).map(
                                province => {
                                    return (
                                        <div key={province} name={province}>
                                            <Accordion minHeight={0}>
                                                {province && veterinarians[province].map(location => (
                                                    <div key={location.id} name={location.label}>
                                                        <Paragraph mb="xs">
                                                            {location.address}
                                                        </Paragraph>
                                                        <Paragraph mb="xs">
                                                            {location.phoneNumber}
                                                        </Paragraph>
                                                        <Paragraph mb="xs">
                                                            <a
                                                                onClick={() => window.location.href = `https://${location.url}`}
                                                                href={`//${location.url}`}
                                                            >
                                                                https://{location.url}
                                                            </a>
                                                        </Paragraph>
                                                    </div>
                                                ))}
                                            </Accordion>
                                        </div>
                                    )
                                })
                            }
                        </Accordion>
                    </Block>
                </Col>
                <Col height={600} width={[1, 1, 1, 1 / 2]} pl={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <GoogleMaps
                        defaultZoom={8}
                        defaultCenter={{lat: 52.671740, lng: 4.804530}}
                        locations={locations}
                    />
                    <Block width={1}>
                        <Heading as="h1">Staat uw praktijk er niet bij?</Heading>
                        <Paragraph>
                            Bent u praktijkeigenaar en maakt u gebruik van onze service, dan kunt u een email
                            sturen naar info@mobieledierenscan.nl om uw praktijk ook op deze website te laten
                            vermelden. Bent u diereigenaar en staat uw eigen praktijk er niet tussen, raadpleeg
                            dan één van de overige praktijken uit de lijst voor een eventuele afspraak, of
                            informeer uw eigen dierenarts over onze dienst.
                        </Paragraph>
                    </Block>
                </Col>
            </Row>
        </Page>
    </>
);

Locations.propTypes = {
    page: PropTypes.object,
    locations: PropTypes.array,
};

Locations.defaultProps = {
    page: {},
    locations: [],
};

export default Locations;
