export const index = [
    require('../Assets/Images/heroBanner/homepage/da-home-1.jpg'),
    require('../Assets/Images/heroBanner/homepage/da-home-2.jpg'),
    require('../Assets/Images/heroBanner/homepage/da-home-3.jpg'),
    require('../Assets/Images/heroBanner/homepage/da-home-4.jpg'),
    require('../Assets/Images/heroBanner/homepage/da-home-5.jpg'),
];
export const aboutUs = [
    require('../Assets/Images/heroBanner/aboutus/da-about-1.jpg'),
    require('../Assets/Images/heroBanner/aboutus/da-about-2.jpg'),
    require('../Assets/Images/heroBanner/aboutus/da-about-3.jpg'),
    require('../Assets/Images/heroBanner/aboutus/da-about-4.jpg'),
];
export const ctScan = [
    require('../Assets/Images/heroBanner/ct-scan/da-ct-scan-1.jpg'),
    require('../Assets/Images/heroBanner/ct-scan/da-ct-scan-2.jpg'),
    require('../Assets/Images/heroBanner/ct-scan/da-ct-scan-3.jpg'),
    require('../Assets/Images/heroBanner/ct-scan/da-ct-scan-4.jpg'),
    require('../Assets/Images/heroBanner/ct-scan/da-ct-scan-5.jpg'),
];
export const contact = [
    require('../Assets/Images/heroBanner/contact/da-contact-1.jpg'),
    require('../Assets/Images/heroBanner/contact/da-contact-2.jpg'),
    require('../Assets/Images/heroBanner/contact/da-contact-3.jpg'),
];

export default {
    index,
    aboutUs,
    ctScan,
    contact,
};
