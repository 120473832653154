import React from 'react';
import PropTypes from 'prop-types';

import HeroBannerImage from "../HeroBanner/HeroBannerImage";

import {Wrapper, Author, Content} from "./style";

const HeroBannerTestimonial = ({testimonial}) => (
    <HeroBannerImage url={testimonial.imageUrl}>
        <Wrapper>
            {testimonial.content && <Content>{testimonial.content}</Content>}
            {testimonial.author && <Author>{testimonial.author}</Author>}
        </Wrapper>
    </HeroBannerImage>
);

HeroBannerTestimonial.propTypes = {
    testimonial: PropTypes.object.isRequired,
};

export default HeroBannerTestimonial;
