import background from '../Assets/Images/background.png';
import logo from '../Assets/Images/logo.svg';
import logoBig from '../Assets/Images/logo-big.png';
import introImage from '../Assets/Images/intro-image.png';
import send from '../Assets/Images/send.svg';
import hamburger from '../Assets/Images/hamburger.svg';
import offices from '../Assets/Images/offices.svg';
import mapMarker from '../Assets/Images/map-marker.svg';
import map from '../Assets/Images/map-nederland.png';

export default {
    send,
    background,
    logo,
    logoBig,
    introImage,
    hamburger,
    offices,
    mapMarker,
    map,
};
