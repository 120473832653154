export default {
    trajan: `
        font-family: 'TrajanProBold',sans-serif;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    `,

    light: `
        font-family: 'SegoeUI',sans-serif;
        font-weight: 100;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    `,

    regular: `
        font-family: 'SegoeUI',sans-serif;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    `,

    medium: `
        font-family: 'SegoeUI',sans-serif;
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    `,

    bold: `
        font-family: 'SegoeUI',sans-serif;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    `,

    xbold: `
        font-family: 'SegoeUI',sans-serif;
        font-weight: 900;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    `,
};
