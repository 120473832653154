export const animalOwnerItems = [
    {
        to: '/diereneigenaar',
        label: 'Home',
        check: true,
    },
    {
        to: '/diereneigenaar/ct-scan',
        label: 'CT-Scan',
    },
    {
        to: '/diereneigenaar/locaties',
        label: 'Locaties',
    },
    {
        to: '/diereneigenaar/contact',
        label: 'Contact',
    },
];

export const animalDoctorItems = [
    {
        to: '/dierenarts',
        label: 'Home',
        check: true,
    },
    {
        to: '/dierenarts/over-ons',
        label: 'Over ons',
    },
    {
        to: '/dierenarts/ct-scan',
        label: 'CT-scan',
    },
    {
        to: '/dierenarts/locaties',
        label: 'Locaties',
    },
    {
        to: '/dierenarts/contact',
        label: 'Contact',
    },
];
