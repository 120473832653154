import styled, {css} from "styled-components";

import {
    space,
    width,
    height,
} from 'styled-system';

export default styled('img')(props => css`
    float: ${props.float || 'left'}

    ${width}
    ${height}
    ${space}
`);
