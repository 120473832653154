import {createGlobalStyle} from 'styled-components';
import fonts from './fonts';
import colors from './colors';
import images from './images';
import {mediaQueries} from './mediaQueries';

import TrajanProBoldFont from '../Assets/Fonts/TrajanProBold.ttf';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        ${fonts.regular};
        font-size: 14px;
        color: ${colors.text};
        line-height: normal;
        
        background: url(${images.background}) no-repeat -100px center fixed;
        background-size: 300px;
        
        @media ${mediaQueries.tablet} {
            background-position: -250px center;
            background-size: 600px;
        }
    }

    a.text-link {
        color: ${colors.primary};
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
        
        &:visted {
            color: ${colors.primary};        
        }
    }
    
    a {
        color: ${colors.primary};
        
        &:visited {
            color: ${colors.primary};        
        }    
    }

    @font-face {
        font-family: TrajanProBold;
        src: url(${TrajanProBoldFont}) format('truetype');
    }

    @font-face {
        font-family: SegoeUI;
        src:
            local("Segoe UI Light"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype");
        font-weight: 100;
    }
    
    @font-face {
        font-family: SegoeUI;
        src:
            local("Segoe UI Semilight"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
        font-weight: 200;
    }
    
    @font-face {
        font-family: SegoeUI;
        src:
            local("Segoe UI"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
        font-weight: 400;
    }
    
    @font-face {
        font-family: SegoeUI;
        src:
            local("Segoe UI Bold"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
        font-weight: 600;
    }
    
    @font-face {
        font-family: SegoeUI;
        src:
            local("Segoe UI Semibold"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"),
            url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("truetype");
        font-weight: 700;
    }
`;
