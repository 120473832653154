import React from 'react';
import {Helmet} from "react-helmet";

import Page from "../Page";

import Block from "../../StyledComponents/Block";
import {Col, Row} from "../../StyledComponents/Grid";
import {Heading, Paragraph} from "../../StyledComponents/Typography";
import {Item, List} from "../../StyledComponents/List";
import {ctScan as HeroBannerImages} from "../../Assets/HeroBannerImages";
import ContactForm from "../../Components/ContactForm";

const CTScan = () => (
    <>
        <Helmet>
            <title>CT-Scan - Mobieledierenscan.nl</title>
            <meta charSet="utf-8"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description"
                  content="Bij uw eigen vertrouwde dierenarts een scan laten uitvoeren geeft minder zorgen voor u en uw zieke huisdier."/>
            <meta name="keywords"
                  content="CT-Scan, Ontzorgen, Huisdier, Onderzoek, Elleboog, Rug, Buik, Kop"/>
            <link rel="canonical" href="https://mobieledierenscan.nl/dierenarts/ct-scan"/>
        </Helmet>

        <Page heroBannerImages={HeroBannerImages}>
            <Row flexDirection={['column', 'column', 'column', 'row']}>
                <Col width={1} my={['sm', 'sm', 'sm', 0]}>
                    <Block width={1} mb="md">
                        <Heading as="h1">De CT-scan</Heading>
                        <Paragraph>
                            Een CT-scan is gebaseerd op hetzelfde principe als conventionele RX-beelden: ze
                            worden beide geproduceerd door absorptie van röntgenstralen in de verschillende
                            weefsels die een verschillende densiteit hebben. Op die manier zullen weinig dense
                            structuren (bijvoorbeeld longen) zwart of grijs zijn op de beelden en heel dense
                            structuren (bijvoorbeeld bot) lichtgrijs tot wit. Het grote verschil tussen beide is
                            dat met CT 2D- en 3D-beelden kunnen gemaakt worden en er geen superpositie is van
                            structuren. Met CT kan men beelden o.a. bekijken in een botvenster waardoor de
                            botstructuren beter in beeld gebracht worden evenals een standaard of weke delen
                            venster waardoor men meer detail krijgt van de weke delen zoals organen en spieren.
                        </Paragraph>
                        <Paragraph>
                            Het gebruik van CT hangt af van patiënt tot patiënt alsook factoren zoals kostprijs
                            en beschikbaarheid van andere beeldvormingstechnieken. CT is meestal duurder dan RX
                            en echografie maar geeft op een snelle, niet invasieve manier méér informatie over
                            afwijkingen bij een patiënt.
                        </Paragraph>
                        <Paragraph>
                            Afhankelijk van de diagnose en de therapie kan er door de praktijk zelf besloten worden wat
                            de vervolgstap voor de patiënt wordt:
                        </Paragraph>
                        <List mb={0}>
                            <Item>Palliatieve behandeling bij eigen vertrouwde dierenarts.</Item>
                            <Item>Chirurgisch ingrijpen:</Item>
                        </List>
                        <Row ml="lg">
                            <List>
                                <Item>door praktijk zelf indien kennis en apparatuur aanwezig</Item>
                                <Item>door ingeschakelde specialist aan huis</Item>
                                <Item>doorverwijzen naar specialist of buurtpraktijk</Item>
                            </List>
                        </Row>
                    </Block>
                </Col>
            </Row>
            <Row flexDirection={['column', 'column', 'column', 'row']}>
                <Col width={1} mr={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <Block width={1} mb="md">
                        <Heading as="h2">Kosten</Heading>
                        <Paragraph>
                            Maakt u geen gebruik van ons, kost het u niets. Maakt u wel gebruik van ons rekent
                            u eenvoudig de kosten van de CT-scan door aan de betreffende klant. Extra
                            diagnostiek zonder investering.
                        </Paragraph>
                        <Paragraph>
                            De eindprijs naar de eigenaar van het dier bepaalt u zelf. VMS zal nooit de kosten
                            voor een scan prijsgeven aan een diereigenaar. Wij hanteren altijd all-in prijzen, dit is
                            inclusief:
                        </Paragraph>
                        <List>
                            <Item>De CT-scan, inclusief de beelden.</Item>
                            <Item>Onderhoud gasanesthesie / creëren van een apneu.</Item>
                            <Item>Contrastvloeistof indien nodig.</Item>
                            <Item>Radiologische beoordeling + het verslag.</Item>
                        </List>
                    </Block>
                    <Block width={1} mb="md">
                        <Heading as="h2">Benieuwd naar onze prijzen? Neem hiervoor contact met ons op.</Heading>
                        <ContactForm contentText="Beste Mobiele dierenscan,
Wat kost een CT-scan?"/>
                    </Block>
                </Col>
            </Row>
            <Row flexDirection={['column', 'column', 'column', 'row']}>
                <Col width={[1, 1, 1, 1 / 2]} mr={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <Block width={1} mb="md">
                        <Heading as="h1">Waarvoor kan CT gebruikt worden?</Heading>
                        <Heading as="h2" fontSize="md">
                            Musculoskeletaal stelsel
                        </Heading>
                        <Paragraph mb="md">
                            Eén van de belangrijkste indicaties van CT is aandoeningen van het skelet in beeld
                            te brengen. Met CT kan men een totaalbeeld krijgen van fracturen of botstructuren
                            zonder superpositie zoals bij RX. Bij evaluatie van spieren en ligamenten is CT
                            minder geschikt dan echografie en/of MRI.
                        </Paragraph>
                        <Paragraph>
                            Meest voorkomende indicaties zijn:<br/>
                            <List>
                                <Item>LPC, OCD, incomplete ossificatie van de humeruscondyl van de elleboog
                                    (elleboogdysplasie)</Item>
                                <Item>OCD van de tarsus</Item>
                                <Item>fracturen</Item>
                                <Item>tumoren</Item>
                                <Item>...</Item>
                            </List>
                        </Paragraph>

                        <Heading as="h2" fontSize="md">
                            Centraal zenuwstelsel
                        </Heading>
                        <Paragraph>
                            CT heeft minder anatomisch detail dan magnetische resonantie (MRI) voor de hersenen,
                            ruggenmerg en zenuwen, maar kan zeker gebruikt worden indien MRI niet voorhanden is.
                            Ook voor afwijkingen aan de wervelkolom zelf is CT bruikbaar door het excellente
                            detail van de botstructuren.
                        </Paragraph>
                        <Paragraph>
                            Belangrijkste indicaties zijn:
                            <List>
                                <Item>
                                    het opsporen van congenitale hersenaandoeningen zoals een hydrocefalus
                                </Item>
                                <Item>intracraniale massa’s zoals meningioma bij de kat</Item>
                                <Item>beoordeling van de hypofyse</Item>
                                <Item>acuut hersentrauma</Item>
                                <Item>discus hernia</Item>
                                <Item>wervelfracturen</Item>
                                <Item>tumoren van het ruggenmerg</Item>
                                <Item>...</Item>
                            </List>
                        </Paragraph>
                    </Block>
                </Col>
                <Col width={[1, 1, 1, 1 / 2]} ml={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <Block width={1} mb="md">
                        <Heading as="h1">Waarvoor kan CT-scan nog meer gebruikt worden?</Heading>
                        <Heading as="h2" fontSize="md">
                            Thorax
                        </Heading>
                        <Paragraph>
                            CT is de techniek bij uitstek om, met uitzondering van het hart, alle thoracale
                            structuren (pleura, mediastinum, bronchi, longparenchym, thoraxwand, …) in beeld te
                            brengen. De patiënt moet voor deze studies onder apnea gebracht worden om
                            bewegingsartefacten die te wijten zijn aan de ademhaling te vermijden.
                        </Paragraph>
                        <Paragraph>
                            Meest voorkomende indicaties zijn:
                            <List>
                                <Item>pulmonaire metastasen (&lt; 3 mm)</Item>
                                <Item>thoraxmassa's</Item>
                                <Item>pneumothorax</Item>
                                <Item>ribtumoren</Item>
                                <Item>...</Item>
                            </List>
                        </Paragraph>

                        <Heading as="h2" fontSize="md">
                            Abdomen
                        </Heading>
                        <Paragraph>
                            CT is na echo (dat goedkoper is en geen algemene anesthesie vereist) de techniek bij
                            uitstek om de abdominale organen in beeld te brengen, voornamelijk in obese of niet
                            meewerkende dieren. De regio’s die moeilijk in beeld te brengen zijn met andere
                            technieken zoals de pancreas en de pelvis regio zijn makkelijk te beoordelen op CT.
                            Voor het onderzoek van het abdomen is steeds intraveneus joodcontrast nodig, dit om
                            beter de organen af te lijnen en hun doorbloeding te kunnen beoordelen.
                        </Paragraph>
                        <Paragraph>
                            Meest voorkomende indicaties zijn:
                            <List>
                                <Item>screening voor tumoren</Item>
                                <Item>portosystemische shunts</Item>
                                <Item>vasculaire invasie van bijniertumoren</Item>
                                <Item>ectopische ureters</Item>
                                <Item>...</Item>
                            </List>
                        </Paragraph>

                        <Heading as="h2" fontSize="md">
                            Full body
                        </Heading>
                        <Paragraph>
                            CT kan gebruikt worden voor de staging van tumoren. Massa’s, longmetastasen,
                            uitbreiding in lymfeknopen en secundaire letsel van botstructuren kunnen op die
                            manier opgespoord worden. Ook in multitrauma patiënten, na bijvoorbeeld een
                            aanrijding, kan CT gebruikt worden als screening.
                        </Paragraph>

                        <Heading as="h2" fontSize="md">
                            Kleine diersoorten zoals konijnen, fretten, hamsters e.d.
                        </Heading>
                        <Paragraph>
                            CT is tevens uiterst geschikt voor het evalueren van tandproblemen bij kleine
                            dieren.
                        </Paragraph>
                    </Block>
                </Col>
            </Row>
        </Page>
    </>
);

export default CTScan;
