export default [
    {
        imageUrl: require('../Assets/Images/heroBanner/testiomonials/zuiderkaag.jpg'),
        author: 'Paula Hendriks - Dierenkliniek Zuiderkaag',
        content: 'Snelheid was een belangrijke factor om voor de samenwerken met jullie te kiezen. De scans waren erg duidelijk en hierdoor konden snel beslissingen gemaakt worden.',
    },
    {
        imageUrl: require('../Assets/Images/heroBanner/testiomonials/vchn.jpg'),
        author: 'Veterinair Centrum Holland Noord',
        content: 'Veterinair Mobiele CT-scan heeft ons een level hoger gebracht in de diergeneeskunde. Patiënten worden gescand aan de praktijk en aanvullend kunnen we gebruik maken van de expertise van dierenarts Nathalie Leenheer-Schigt. Echt een hele fijne samenwerking.'
    },
    {
        imageUrl: require('../Assets/Images/heroBanner/testiomonials/ranzijn.jpg'),
    },
    {
        imageUrl: require('../Assets/Images/heroBanner/testiomonials/dieren-dokters-zeist.jpg'),
    },
    {
        imageUrl: require('../Assets/Images/heroBanner/testiomonials/maastricht.jpg'),
    },
];
