import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

import Page from "../Page";

import Block from "../../StyledComponents/Block";
import {Col, Row} from "../../StyledComponents/Grid";
import {Heading, Paragraph} from "../../StyledComponents/Typography";
import {Item, List} from "../../StyledComponents/List";

import {ctScan as HeroBannerImages} from "../../Assets/HeroBannerImages";

const CTScan = () => (
    <>
        <Helmet>
            <title>CT-Scan - Mobieledierenscan.nl</title>
            <meta charSet="utf-8"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description"
                  content="Bij uw eigen vertrouwde dierenarts een scan laten uitvoeren geeft minder zorgen voor u en uw zieke huisdier."/>
            <meta name="keywords"
                  content="CT-Scan, Ontzorgen, Huisdier, Onderzoek, Elleboog, Rug, Buik, Kop"/>
            <link rel="canonical" href="https://mobieledierenscan.nl/diereneigenaar/ct-scan"/>
        </Helmet>

        <Page heroBannerImages={HeroBannerImages}>
            <Row flexDirection={['column', 'column', 'column', 'row']}>
                <Col width={1} my={['sm', 'sm', 'sm', 0]}>
                    <Block width={1} mb="md">
                        <Heading as="h1">Waarom een CT-scan laten uitvoeren</Heading>
                        <Paragraph>
                            Een CT-scan is gebaseerd op hetzelfde principe als conventionele RX
                            (röntgen)-beelden: ze worden beide geproduceerd door absorptie van röntgenstralen in
                            de verschillende weefsels. Op die manier zullen weinig dense structuren
                            (bijvoorbeeld longen) zwart of grijs zijn op de beelden en heel dense structuren
                            (bijvoorbeeld bot) lichtgrijs tot wit. Het grote verschil tussen beide is dat met CT
                            2D- en 3D-beelden kunnen gemaakt worden en er geen overlap is van structuren. Met CT
                            kan men beelden o.a. bekijken in een botvenster waardoor de botstructuren beter in
                            beeld gebracht worden. Ook kan er een standaard of weke delen venster gebruikt
                            worden waardoor men meer detail krijgt van de weke delen zoals organen en spieren.
                        </Paragraph>
                        <Paragraph>
                            CT is meestal duurder dan RX en echografie maar geeft op een snelle, niet invasieve
                            manier méér informatie over afwijkingen bij een patiënt.
                        </Paragraph>
                    </Block>
                </Col>
            </Row>
            <Row flexDirection={['column', 'column', 'column', 'row']}>
                <Col width={[1, 1, 1, 1 / 2]} mr={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <Block width={1} mb="md">
                        <Heading as="h1">Waarvoor kan CT gebruikt worden?</Heading>
                        <Heading as="h2" fontSize="md">
                            Musculoskeletaal stelsel
                        </Heading>
                        <Paragraph mb="md">
                            Eén van de belangrijkste indicaties van CT is aandoeningen van het skelet in beeld
                            te brengen. Met CT kan men een totaalbeeld krijgen van fracturen of botstructuren
                            zonder overlap zoals bij RX. Bij evaluatie van spieren en ligamenten is CT minder
                            geschikt dan echografie en/of MRI.
                        </Paragraph>
                        <Paragraph>
                            Meest voorkomende indicaties zijn:<br/>
                            <List>
                                <Item>LPC, OCD, incomplete ossificatie van de humeruscondyl van de elleboog
                                    (elleboogdysplasie)</Item>
                                <Item>OCD van de tarsus</Item>
                                <Item>fracturen</Item>
                                <Item>tumoren</Item>
                            </List>
                        </Paragraph>

                        <Heading as="h2" fontSize="md">
                            Centraal zenuwstelsel
                        </Heading>
                        <Paragraph>
                            Voor afwijkingen aan de wervelkolom is CT bruikbaar door het excellente detail van
                            de botstructuren. CT heeft weliswaar minder anatomisch detail dan magnetische
                            resonantie (MRI) voor de hersenen, ruggenmerg en zenuwen, maar kan zeker ook
                            gebruikt worden indien MRI niet voorhanden is.
                        </Paragraph>
                        <Paragraph>
                            Belangrijkste indicaties zijn:
                            <List>
                                <Item>het opsporen van congenitale hersenaandoeningen zoals een
                                    hydrocefalus</Item>
                                <Item>intracraniale massa’s zoals meningioma bij de kat</Item>
                                <Item>beoordeling van de hypofyse</Item>
                                <Item>discus hernia</Item>
                                <Item>wervelfracturen</Item>
                                <Item>tumoren van het ruggenmerg</Item>
                            </List>
                        </Paragraph>
                    </Block>
                </Col>
                <Col width={[1, 1, 1, 1 / 2]} ml={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <Block width={1} mb="md">
                        <Heading as="h1">Waarvoor kan CT-scan nog meer gebruikt worden?</Heading>
                        <Heading as="h2" fontSize="md">
                            Thorax
                        </Heading>
                        <Paragraph>
                            CT is de techniek bij uitstek om, met uitzondering van het hart, alle thoracale
                            structuren (pleura, mediastinum, bronchi, longparenchym, thoraxwand, …) in beeld te
                            brengen. De patiënt moet voor deze studies onder apnea gebracht worden om
                            bewegingsartefacten die te wijten zijn aan de ademhaling te vermijden.
                        </Paragraph>
                        <Paragraph>
                            Meest voorkomende indicaties zijn:
                            <List>
                                <Item>pulmonaire metastasen (&lt; 3 mm)</Item>
                                <Item>thoraxmassa's</Item>
                                <Item>pneumothorax</Item>
                                <Item>ribtumoren</Item>
                            </List>
                        </Paragraph>

                        <Heading as="h2" fontSize="md">
                            Abdomen
                        </Heading>
                        <Paragraph>
                            CT is na echo de techniek bij uitstek om de abdominale (buik) organen in beeld te
                            brengen, voornamelijk in obese of niet meewerkende dieren. De regio’s die moeilijk
                            in beeld te brengen zijn met andere technieken, zoals de pancreas en de pelvis
                            regio, zijn makkelijk te beoordelen op CT. Voor het onderzoek van het abdomen is
                            steeds intraveneus joodcontrast (contrastvloeistof) nodig, dit om beter de organen
                            af te lijnen en hun doorbloeding te kunnen beoordelen.
                        </Paragraph>
                        <Paragraph>
                            Meest voorkomende indicaties zijn:
                            <List>
                                <Item>screening voor tumoren</Item>
                                <Item>portosystemische shunts</Item>
                                <Item>vasculaire invasie van bijniertumoren</Item>
                                <Item>ectopische ureters</Item>
                            </List>
                        </Paragraph>

                        <Heading as="h2" fontSize="md">
                            Full body
                        </Heading>
                        <Paragraph>
                            CT kan gebruikt worden voor de staging van tumoren. Massa’s, longmetastasen,
                            uitbreiding in lymfeknopen en secundaire letsel van botstructuren kunnen op die
                            manier opgespoord worden. Ook in multitrauma patiënten, na bijvoorbeeld een
                            aanrijding, kan CT gebruikt worden als screening.
                        </Paragraph>

                        <Heading as="h2" fontSize="md">
                            Kleine diersoorten zoals konijnen, fretten, hamsters e.d.
                        </Heading>
                        <Paragraph>
                            CT is tevens uiterst geschikt voor het evalueren van tandproblemen bij kleine
                            dieren.
                        </Paragraph>
                    </Block>
                </Col>
            </Row>
        </Page>
    </>
);

CTScan.propTypes = {
    page: PropTypes.object,
};

CTScan.defaultProps = {
    page: {},
};

export default CTScan;
