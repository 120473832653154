import React, {useState} from "react";
import PropTypes from "prop-types";
import axios from "axios";

import {Form, Input, SubmitButton, TextArea} from "../../StyledComponents/Form";
import {Row} from "../../StyledComponents/Grid";

import {Label} from "./style";

const ContactForm = ({isAnimalOwner, isAnimalDoctor, contentText}) => {
    const [sendSuccessful, setSendSuccessful] = useState(false);
    const [sendingMail, setSendingMail] = useState(false);
    const [messages, setMessages] = useState(null);

    const clinicRef = React.createRef();
    const nameRef = React.createRef();
    const petNameRef = React.createRef();
    const petKindRef = React.createRef();
    const msgRef = React.createRef();
    const emailRef = React.createRef();
    const phoneRef = React.createRef();

    const onSubmit = () => {
        setSendingMail(true);
        setMessages(null);

        const data = {
            clinic: clinicRef.current && clinicRef.current.value,
            name: nameRef.current && nameRef.current.value,
            petName: petNameRef.current && petNameRef.current.value,
            petKind: petKindRef.current && petKindRef.current.value,
            message: msgRef.current && msgRef.current.value,
            phone: phoneRef.current && phoneRef.current.value,
            from: emailRef.current && emailRef.current.value,
            subject: `Contact`,
        };

        axios.post('/contact.php', data)
            .then((response) => {
                    setSendingMail(false);
                    setSendSuccessful(true);
                    setMessages(response.data.messages);
                },
                (error) => {
                    setSendingMail(false);
                    setSendSuccessful(false);
                    setMessages(error.response.data.message);
                })
            .catch((error) => {
                setSendingMail(false);
                setSendSuccessful(false);
                setMessages(error.response.data.message);
            });
    };

    return sendSuccessful ? (
        <Row>
            {messages}
        </Row>
    ) : (
        <Form flexDirection="column">
            {isAnimalDoctor && (
                <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                    <Label required width={[1, 1, 1, 2 / 5]}>Praktijknaam:</Label>
                    <Input ref={clinicRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]}
                           name="officename"/>
                </Row>
            )}
            <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                <Label required width={[1, 1, 1, 2 / 5]}>Uw naam:</Label>
                <Input ref={nameRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]} name="name"/>
            </Row>
            {isAnimalOwner && (
                <>
                    <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                        <Label required width={[1, 1, 1, 2 / 5]}>Naam van uw dier:</Label>
                        <Input ref={petNameRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]}
                               name="officename"/>
                    </Row>
                    <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                        <Label required width={[1, 1, 1, 2 / 5]}>Soort dier:</Label>
                        <Input ref={petKindRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]}
                               name="officename"/>
                    </Row>
                </>
            )}
            <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                <Label required width={[1, 1, 1, 2 / 5]}>Uw e-mail:</Label>
                <Input ref={emailRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]} name="email"
                       type="email"/>
            </Row>
            {isAnimalDoctor && (
                <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                    <Label required width={[1, 1, 1, 2 / 5]}>Uw telefoonnr:</Label>
                    <Input ref={phoneRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]} name="phone"
                           type="phone"/>
                </Row>
            )}
            <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                <Label required width={[1, 1, 1, 2 / 5]}>Uw bericht:</Label>
                <TextArea ref={msgRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]} name="date" defaultValue={contentText} />
            </Row>
            <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                <Label width={[1, 1, 1, 3 / 5]}>Velden met een * zijn verplicht</Label>
                <SubmitButton disabled={!!sendingMail} onClick={onSubmit} width={[1, 1, 1, 2 / 5]}>
                    Verstuur
                </SubmitButton>
            </Row>
            {messages && (
                <Row>
                    {messages}
                </Row>
            )}
        </Form>
    );
};

ContactForm.propTypes = {
    isAnimalOwner: PropTypes.bool,
    isAnimalDoctor: PropTypes.bool,
    contentText: PropTypes.string,
};

ContactForm.defaultProps = {
    isAnimalOwner: false,
    isAnimalDoctor: false,
    contentText: '',
};

export default ContactForm;
