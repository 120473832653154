import styled, {css} from "styled-components";
import {Button} from "../../StyledComponents/Button";

export const SelectionButton = styled(Button)(props => css`
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 1em;
    text-decoration: none; 
    
    @media ${props.theme.mediaQueries.laptop} {
        font-size: 2em;
    }
`);
