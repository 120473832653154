import React, {useState} from 'react';

import AccordionSection from './AccordionSection';
import {List} from './style';

const Accordion = ({children, ...props}) => {
    const [openSections, setOpenSections] = useState({});

    const onClick = (e, name) => {
        e.preventDefault();

        if (e.target === e.currentTarget) {
            const isOpen = !!openSections[name];
            setOpenSections({[name]: !isOpen});
        }
    };

    return (
        <List minHeight={props.minHeight}>
            {children && children.map(child => (
                <AccordionSection
                    key={child.key}
                    isOpen={!!openSections[child.props.name]}
                    label={child.props.name}
                    onClick={onClick}
                >
                    {child.props.children}
                </AccordionSection>
            ))}
        </List>
    );
};

export default Accordion;
