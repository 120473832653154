import PropTypes from "prop-types";
import React from "react";
import {Cover, VideoBackground, VideoForeground, VideoWrapper} from "./style";
import ReactPlayer from "react-player";

const Video = (props) => {

    return (
        <VideoWrapper>
            <VideoBackground>
                <VideoForeground>
                    <ReactPlayer url={props.src} loop controls={false} playing volume={0} muted />
                </VideoForeground>
            </VideoBackground>
            <Cover {...props}>
                {props.children}
            </Cover>
        </VideoWrapper>
    )
};

Video.propTypes = {
    src: PropTypes.string.isRequired,
    fallback: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    children: PropTypes.node,
};

Video.defaultProps = {
    fallback: null,
    width: 0,
    height: 0,
    children: null,
};

export default Video;
