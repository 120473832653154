import React from 'react';
import {Helmet} from "react-helmet";

import Page from "./Page";

import {Heading, Paragraph} from "../StyledComponents/Typography";
import {Item, List} from "../StyledComponents/List";
import Block from "../StyledComponents/Block";

import {index as HeroBannerImages} from "../Assets/HeroBannerImages";

export default () => (
    <>
        <Helmet>
            <title>Algemene Voorwaarden - Mobieledierenscan.nl</title>
            <meta charSet="utf-8"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description"
                  content="Heb je een vraag of wilt u meer informatie over de CT-scan en procedure? Dan kunt u contact opnemen met Mobieledierenscan. Het algemene telefoonnumer is 06 82 29 55 77"/>
            <meta name="keywords"
                  content="Contact, Informatie, Bellen, Email, Mobieledierenscan, Kliniek"/>
            <link rel="canonical" href="https://mobieledierenscan.nl/dierenarts/contact"/>
        </Helmet>

        <Page heroBannerImages={HeroBannerImages}>
            <Block width={1} mb="md">
                <Heading as="h2">Preambule</Heading>
                <Paragraph>
                    Veterinair Mobiele CT-scan is een eenmanszaak waarin voor rekening en risico van mevrouw Agatha
                    Catharina Nathalie Leenheer-Schigt diensten worden aangeboden in het kader van de zorg van
                    huisdieren. De diensten van Veterinair Mobiele CT-scan worden uitsluitend aan dierenartsen en
                    dierenartspraktijken aangeboden (B2B) en bestaan in het bijzonder uit het uitvoeren van CT-scans op
                    huisdieren die bij de dierenartspraktijk worden binnen gebracht.
                    Veterinair Mobiele CT-scan is in het handelsregister geregistreerd onder het KvK-nummer 72675519
                    en gevestigd aan de Ooster Vlaerdinge 73 te 1703 MX Heerhugowaard.
                </Paragraph>

                <Heading as="h2">Artikel 1 - Definities</Heading>

                <List>
                    <Item>
                        Algemene voorwaarden: alle bepalingen uit de hieronder omschreven Algemene
                        voorwaarden
                    </Item>
                    <Item>
                        Opdrachtgever: de dierenarts of dierenartspraktijk die met Veterinaire Mobiele CT-scan een
                        overeenkomst van opdracht heeft gesloten waarop deze algemene voorwaarden van
                        toepassing zijn
                    </Item>
                    <Item>
                        Dierenarts: degene die krachtens de Wet op het wetenschappelijk onderwijs (Stb. 1985, 562),
                        de hoedanigheid van dierenarts heeft verkregen, of op grond van de Wet op de uitoefening
                        van de diergeneeskunde is toegelaten, die geregistreerd is in het Dierengeneeskunderegister
                        en die in opdracht van de cliënt diergeneeskundige behandelingen verricht en/of, in het
                        kader
                        daarvan medicamenten levert en/of verkoopt en/of toedient en/of overige
                        diergeneeskundige adviezen geeft en diergeneeskundige diensten verricht
                    </Item>
                    <Item>
                        Dierenartspraktijk: de dierenarts(en) als hierboven gedefinieerd alsmede de praktijk die de
                        dierenarts(en) met gebruikmaking van alle (hulp)personen, waaronder begrepen maar niet
                        uitsluitend dierenartsen, assistentes, laboranten e.d., al dan niet op basis van een
                        arbeidsovereenkomst voert/voeren, in welke juridische rechtsvorm en/of
                        samenwerkingsvorm dan ook
                    </Item>
                    <Item>
                        Radioloog: universitair opgeleide doctor in de diergeneeskunde, medische beeldvorming CT-
                        MRI, echografie, RX, scintigrafie.
                    </Item>
                    <Item>
                        Cliënt: de eigenaar van de te behandelen patiënt en/of de aanbieder van de patiënt in wiens
                        opdracht de dierenartspraktijk de diergeneeskundige behandelingen verricht en/of, in het
                        kader daarvan medicamenten levert en/of verkoopt en/of toedient en/of overige
                        diergeneeskundige adviezen geeft en diergeneeskundige diensten verricht
                    </Item>
                    <Item>
                        Patiënt(en): het door de cliënt aan de dierenartspraktijk aangeboden dier, dieren of groepen
                        van dieren en/of het dier, de dieren of groepen van dieren waarover en/of ten behoeve
                        waarvan medicamenten worden geleverd en/of toegediend en/of overige diergeneeskundige
                        adviezen worden gegeven en diergeneeskundige diensten worden verricht
                    </Item>
                </List>

                <Heading as="h2">Artikel 2. Toepasselijkheid algemene voorwaarden</Heading>
                <Paragraph>
                    2.1. Deze algemene voorwaarden zijn van toepassing op alle door Veterinair Mobiele CT-scan
                    aanvaarde opdrachten, vervolgopdrachten en overige werkzaamheden.
                </Paragraph>
                <Paragraph>
                    2.2. De toepasselijkheid van andersluidende voorwaarden van opdrachtgever wordt uitdrukkelijk
                    afgewezen.
                </Paragraph>

                <Heading as="h2">Artikel 3. Totstandkoming overeenkomst</Heading>
                <Paragraph>
                    3.1.Een overeenkomst tussen Veterinair Mobiele CT-scan en opdrachtgever komt tot stand in de
                    navolgende gevallen :
                </Paragraph>
                <List>
                    <Item>
                        Veterinair Mobiele CT-scan stuurt een offerte met algemene voorwaarden per e-mail
                        aan opdrachtgever en opdrachtgever bevestigt per e-mail dat hij akkoord gaat met de
                        offerte én de algemene voorwaarden of stuurt deze stukken ondertekend retour;
                    </Item>
                    <Item>
                        Veterinair Mobiele CT-scan stuurt een concept overeenkomst met algemene
                        voorwaarden per e-mail of per post en opdrachtgever stuurt een ondertekend
                        exemplaar van de overeenkomst per e-mail of per post retour aan Veterinair Mobiele
                        CT-scan
                    </Item>
                    <Item>
                        Veterinair Mobiele CT-scan aanvaardt, in geval van spoedeisendheid, een mondelinge
                        opdracht van opdrachtgever en bereikt mondeling overeenstemming over de inhoud
                        van de overeenkomst
                    </Item>
                    <Item>
                        Veterinair Mobiele CT-scan is begonnen met de uitvoering van de overeenkomst ;
                        daarvan is in ieder geval sprake als Veterinair Mobiele CT-scan een scan heeft
                        uitgevoerd op de patiënt.
                    </Item>
                </List>

                <Paragraph>
                    3.2.Het is ook mogelijk dat een combinatie van bovenstaande situaties zich voordoet. Zo kunnen
                    Veterinair Mobiele CT-scan en opdrachtgever een raamovereenkomst sluiten gedurende een
                    bepaalde periode en kan opdrachtgever binnen de voorwaarden van die raamovereenkomst
                    telkens mondeling of per e-mail een opdracht geven tot een nieuwe scan.
                </Paragraph>

                <Heading as="h2">Artikel 4. Inhoud overeenkomst</Heading>
                <Paragraph>
                    4.1.De diensten van Veterinair Mobiele CT-scan bestaan uit het verrichten van CT-scans, tegen
                    betaling van een vergoeding. Hieronder worden in ieder geval verstaan de volgende
                    werkzaamheden:
                </Paragraph>
                <List>
                    <Item>
                        De patiënt onder narcose houden
                    </Item>
                    <Item>
                        De CT-scan uitvoeren
                    </Item>
                    <Item>
                        Het beeldmateriaal van de CT-scan door een radioloog laten beoordelen en deze
                        beoordeling terugkoppelen aan de opdrachtgever
                    </Item>
                    <Item>
                        Het beeldmateriaal en de beoordeling van de radioloog met opdrachtgever en indien
                        nodig met de cliënt bespreken.
                    </Item>
                </List>

                <Paragraph>
                    4.2.Het staat opdrachtgever vrij om bij een zelf gekozen radioloog op eigen kosten een second
                    opinion aan te vragen. De werkzaamheden van Veterinair Mobiele CT-scan zijn in dat geval beperkt tot
                    het onder narcose brengen van de patiënt, het uitvoeren van de CT-scan en het beeldmateriaal
                    aan de (radioloog van de) opdrachtgever ter beschikking stellen ter beoordeling. Veterinair
                    Mobiele CT-scan aanvaardt evenwel geen enkele aansprakelijkheid voor fouten die door
                    hulppersonen van de opdrachtgever worden gemaakt.
                </Paragraph>
                <Paragraph>
                    4.3.De CT-scan bevindt zich in een vrachtwagen. De diensten van Veterinair Mobiele CT-scan
                    omvatten derhalve mede het bezoeken van opdrachtgever op zijn vestigingsadres of het door hem
                    aangewezen bezoekadres ten behoeve van bovengenoemde werkzaamheden. Voor de reistijd en
                    reiskosten wordt afzonderlijk een vergoeding overeengekomen.
                </Paragraph>

                <Heading as="h2">Artikel 5. Annulering opdracht / opzeggen (raam)overeenkomst</Heading>
                <Paragraph>
                    5.1. Het staat opdrachtgever vrij om de opdracht aan Veterinair Mobiele CT Scan te annuleren.
                    Als de annulering echter plaatsvindt binnen vier uur voordat de opdracht door Veterinair Mobiele CT-
                    scan zou worden uitgevoerd of Veterinair Mobiele CT-scan al onderweg is naar opdrachtgever, dan
                    is opdrachtgever 50% van de overeengekomen som verschuldigd aan Veterinair Mobiele CT-scan.
                </Paragraph>
                <Paragraph>
                    5.2.Indien de werkzaamheden van Mobiele CT-scan plaatsvinden in het kader van een
                    raamovereenkomst waarbij partijen zijn overeengekomen dat Veterinair Mobiele CT-scan
                    gedurende een overeengekomen periode minstens een overeengekomen aantal CT-scans voor
                    opdrachtgever zal uitvoeren, dan is opdrachtgever verplicht om voor dit overeengekomen aantal
                    scans de afgesproken prijs te betalen ook al laat hij minder scans uitvoeren dan in de
                    overeenkomst is bepaald. Tussentijdse opzegging van dit type duurovereenkomsten is niet
                    mogelijk.
                </Paragraph>

                <Heading as="h2">Artikel 6. Tarieven, honoraria en betalingen</Heading>
                <Paragraph>
                    6.1 Tenzij anders vermeld zijn de bedragen die Veterinair Mobiele CT-scan in haar offertes,
                    concept
                    overeenkomsten en andere aanbiedingen noemt, exclusief BTW. Op de nota wordt de BTW
                    uitgesplitst vermeld.
                </Paragraph>
                <Paragraph>
                    6.2 Betaling van de nota kan contant geschieden direct na de verrichte werkzaamheden, tenzij
                    anders
                    overeengekomen. Voor niet contante betalingen geldt standaard een betalingstermijn van 14
                    dagen. Bij afzonderlijke nota kan een andere betalingstermijn worden vermeld. Veterinair
                    Mobiele CT-scan is evenwel gerechtigd om in voorkomende gevallen betaling of een aanbetaling
                    vooraf te verlangen en niet eerder met de werkzaamheden aan te vangen dan nadat het bedrag
                    door Veterinair Mobiele CT-scan is ontvangen. Tevens is Veterinair Mobiele CT-scan gerechtigd
                    om niet eerder aan te vangen met de werkzaamheden dan nadat eventuele achterstallige
                    betalingen door opdrachtgever zijn ingelopen.
                </Paragraph>
                <Paragraph>
                    6.3 Bij niet tijdige betaling van de nota kan Veterinair Mobiele CT-scan administratiekosten in
                    rekening brengen bij het verzenden van de eerste betalingsherinnering. Tevens is opdrachtgever
                    bij niet tijdige betaling van rechtswege in verzuim en een contractuele vertragingsrente van 8%
                    op het notabedrag verschuldigd ;
                </Paragraph>
                <Paragraph>
                    6.4 Indien betaling uitblijft en Veterinair Mobiele CT-scan de vordering op opdrachtgever uit
                    handen
                    geeft, dan is opdrachtgever tevens de volledige buitengerechtelijke kosten en eventuele
                    proceskosten verschuldigd. Veterinair Mobiele CT-scan kan er ook voor kiezen om de door de
                    rechtspraak vastgestelde gestaffelde incasso- en proceskosten in rekening te brengen
                    (BIK-staffel
                    voor de buitengerechtelijke kosten en de liquidatietarieven voor de proceskosten).
                </Paragraph>
                <Paragraph>
                    6.5 Opdrachtgever is niet gerechtigd verrekening toe te passen, uit welke hoofde dan ook.
                </Paragraph>

                <Heading as="h2">Artikel 7. Klachtplicht, aansprakelijkheid en verjaringstermijn</Heading>
                <Paragraph>
                    7.1.Opdrachtgever is verplicht om onverwijld de door Veterinair Mobiele CT-scan verrichte
                    prestatie
                    te keuren op tekortkomingen. Klachten over de verrichte diensten dient opdrachtgever binnen 30
                    dagen nadat de werkzaamheden zijn beëindigd aan Veterinair Mobiele CT -scan kenbaar te
                    maken, dit dient bij voorkeur schriftelijk of per e-mail te geschieden.
                </Paragraph>
                <Paragraph>
                    7.2.Indien Veterinair Mobiele CT-scan een klacht over de verrichte prestatie gegrond bevindt,
                    heeft
                    zij te allen tijde het recht om de prestatie alsnog te verrichten of het door opdrachtgever
                    verschuldigde bedrag te crediteren.
                </Paragraph>
                <Paragraph>
                    7.3.Indien deugdelijke nakoming niet langer mogelijk is of er naast een gebrek in de prestatie
                    door de
                    opdrachtgever (gevolg)schade wordt geleden, dan kan opdrachtgever slechts aanspraak maken
                    op schadevergoeding in de volgende gevallen :
                </Paragraph>
                <List>
                    <Item>
                        Veterinair Mobiele CT-scan erkent de aansprakelijkheid
                    </Item>
                    <Item>
                        De aansprakelijkheidsverzekering van Veterinair Mobiele CT-scan erkent de
                        aansprakelijkheid eveneens en is bereid om de schade van opdrachtgever te
                        vergoeden
                    </Item>
                </List>
                <Paragraph>
                    7.4.Veterinair Mobiele CT-scan zal zich inspannen om de opdracht naar beste weten en kunnen te
                    verrichten op het afgesproken tijdstip, tenzij zij haar verplichtingen vanwege overmacht niet
                    kan vervullen. Onder overmacht wordt in ieder geval verstaan de onmogelijkheid om de opdracht uit
                    te voeren omdat het vanwege weersomstandigheden niet veilig is om met de mobiele CT-scan
                    over de weg te gaan of de scanapparatuur buiten toedoen van Veterinair Mobiele CT-scan
                    beschadigd is of verloren is gegaan. Indien zich zo een situatie voordoet, dan zal Veterinair
                    Mobiele CT-scan, indien nakoming niet blijvend onmogelijk is geworden, zo snel mogelijk een
                    nieuwe afspraak met opdrachtgever inplannen zonder extra kosten in rekening te brengen. Bij
                    overmacht is aansprakelijkheid van Veterinair Mobiele CT-scan voor door opdrachtgever geleden
                    schade te allen tijde uitgesloten.
                </Paragraph>
                <Paragraph>
                    7.5.Iedere aansprakelijkheid van Veterinair Mobiele CT-scan is beperkt tot het bedrag dat in het
                    betreffende geval onder de aansprakelijkheidsverzekering wordt uitbetaald, vermeerderd met het
                    bedrag ter zake van het onder de beroepsaansprakelijkheidsverzekering toepasselijke eigen
                    risico. Als, om welke reden dan ook, geen uitkering volgt op grond van de
                    aansprakelijkheidsverzekering,
                    beperkt de aansprakelijkheid zich tot het bedrag dat in het betreffende geval reeds is
                    gefactureerd
                    tot een maximum van € 5.000.
                </Paragraph>
                <Paragraph>
                    7.6.Verstrekte opdrachten worden door Veterinair Mobiele CT-scan uitsluitend uitgevoerd ten
                    behoeve van de dierenarts(praktijk) als opdrachtgever. Derden kunnen aan de wijze waarop aan
                    die door de opdrachtgever verstrekte opdracht uitvoering is gegeven, geen rechten ontlenen. De
                    opdrachtgever vrijwaart Veterinair Mobiele CT-scan tegen aanspraken van derden
                    dienaangaande. Veterinair Mobiele CT-scan gaat er daarbij vanuit dat opdrachtgever adequaat is
                    verzekerd tegen beroepsaansprakelijkheidsclaims.
                </Paragraph>
                <Paragraph>
                    7.7.De verjaringstermijn bedraagt maximaal één jaar en kan enkel door middel van een exploot
                    worden gestuit. Opdrachtgever dient vervolgens binnen een vervaltermijn van zes maanden na
                    de aansprakelijkstelling de gerechtelijke procedure op te starten.
                </Paragraph>
                <Paragraph>
                    7.8.Bij overschrijding van de klachttermijn van 30 dagen, de verjaringstermijn van één jaar en
                    de
                    vervaltermijn van 6 maanden, verliest opdrachtgever iedere aanspraak die hij jegens Veterinair
                    Mobiele CT-scan mocht hebben.
                </Paragraph>

                <Heading as="h2">Artikel 8. Hulppersonen</Heading>
                <Paragraph>
                    8.1. Tenzij met de opdrachtgever anders is afgesproken, is Veterinair Mobiele CT-scan bij de
                    uitvoering van een opdracht gerechtigd om derden in te schakelen, echter zonder enige
                    aansprakelijkheid te aanvaarden voor mogelijke tekortkomingen van deze derden.
                </Paragraph>
                <Paragraph>
                    8.2. Voor zover derden hun aansprakelijkheid voor beroepsfouten beperken, is Veterinair
                    Mobiele CT-scan gerechtigd om een dergelijke aansprakelijkheidsbeperking mede
                    namens de opdrachtgever te aanvaarden.
                </Paragraph>
                <Paragraph>
                    8.3. Deze algemene voorwaarden zijn mede bedongen ten behoeve van diegenen die namens
                    Veterinair Mobiele CT-scan bij de uitvoering van een opdracht betrokken zijn.
                </Paragraph>

                <Heading as="h2">Artikel 9. Toepasselijk recht en geschillen</Heading>
                <Paragraph>
                    9.1.De overeenkomst tussen opdrachtgever en Veterinair Mobiele CT-scan is onderworpen aan
                    Nederlands recht.
                </Paragraph>
                <Paragraph>
                    9.2.Uitsluitend de rechtbank Noord-Holland is bevoegd om kennis te nemen van geschillen tussen
                    de opdrachtgever en Veterinair Mobiele CT-scan.
                </Paragraph>
            </Block>
        </Page>
    </>
);
