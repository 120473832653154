import React from 'react';
import {Helmet} from "react-helmet";

import Page from "./Page";

import {Heading, Paragraph} from "../StyledComponents/Typography";
import Block from "../StyledComponents/Block";

import {index as HeroBannerImages} from "../Assets/HeroBannerImages";


export default () => (
    <>
        <Helmet>
            <title>Privacy Statement - Mobieledierenscan.nl</title>
            <meta charSet="utf-8"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description"
                  content="Heb je een vraag of wilt u meer informatie over de CT-scan en procedure? Dan kunt u contact opnemen met Mobieledierenscan."/>
            <meta name="keywords"
                  content="Contact, Informatie, Bellen, Email, Mobieledierenscan, Kliniek"/>
            <link rel="canonical" href="https://mobieledierenscan.nl/dierenarts/contact"/>
        </Helmet>

        <Page heroBannerImages={HeroBannerImages}>
            <Block width={1} mb="md">
                <Paragraph>
                    Veterinair Mobiele CT-scan, gevestigd aan Ooster Vlaerdinge 73, 1704MX Heerhugowaard, is
                    verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze
                    privacyverklaring.
                </Paragraph>

                <Heading as="h2">Contactgegevens</Heading>
                <Paragraph>
                    www.mobieledierenscan.nl
                    Ooster Vlaerdinge 73
                    1704MX Heerhugowaard
                    +31682295577
                    Robbert Leenheer is de Functionaris Gegevensbescherming van Veterinair Mobiele CT-scan. Hij is
                    te
                    bereiken via info@mobieledierenscan.nl.
                </Paragraph>

                <Heading as="h2">Persoonsgegevens die wij verwerken</Heading>
                <Paragraph>
                    Veterinair Mobiele CT-scan verwerkt uw persoonsgegevens doordat u gebruik maakt van onze
                    diensten en/of omdat u deze zelf aan ons verstrekt.
                    Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:<br/>
                    - Voor- en achternaam<br/>
                    - E-mailadres<br/>
                    - Internetbrowser en apparaat type<br/>
                    <br/>
                    Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
                    Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers
                    die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet
                    controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de
                    online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld
                    worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die toestemming
                    persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via
                    info@mobieledierenscan.nl, dan verwijderen wij deze informatie.
                </Paragraph>

                <Heading as="h2">Met welk doel en op basis van welke grondslag wij persoonsgegevens
                    verwerken</Heading>
                <Paragraph>
                    Veterinair Mobiele CT-scan verwerkt uw persoonsgegevens voor de volgende doelen:
                    - Verzenden van onze nieuwsbrief en/of reclamefolder
                    - U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen
                    voeren
                </Paragraph>

                <Heading as="h2">Geautomatiseerde besluitvorming</Heading>
                <Paragraph>
                    Veterinair Mobiele CT-scan neemt niet op basis van geautomatiseerde verwerkingen besluiten over
                    zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die
                    worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld
                    een medewerker van Veterinair Mobiele CT-scan) tussen zit.
                </Paragraph>

                <Heading as="h2">Hoe lang we persoonsgegevens bewaren</Heading>
                <Paragraph>
                    Veterinair Mobiele CT-scan bewaart uw persoonsgegevens niet langer dan strikt nodig is om de
                    doelen
                    te realiseren waarvoor uw gegevens worden verzameld.
                    Wij bewaren uw gegevens zolang u cliënt van ons bent. Dit betekent dat wij uw klantprofiel
                    bewaren
                    totdat u aangeeft dat u niet langer van onze diensten gebruik wenst te maken. Als u dit bij ons
                    aangeeft
                    zullen wij dit tevens opvatten als een vergeetverzoek. Op grond van toepasselijke
                    administratieve
                    verplichtingen dienen wij facturen met uw (persoons)gegevens te bewaren, deze gegevens zullen
                    wij
                    dus voor zolang de toepasselijke termijn loopt bewaren. Medewerkers hebben echter geen toegang
                    meer tot uw cliëntprofiel en documenten die wij naar aanleiding van uw opdracht hebben
                    vervaardigd.
                </Paragraph>

                <Heading as="h2">Delen van persoonsgegevens met derden</Heading>
                <Paragraph>
                    Veterinair Mobiele CT-scan verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend
                    indien
                    dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke
                    verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een
                    bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid
                    van
                    uw gegevens. Veterinair Mobiele CT-scan blijft verantwoordelijk voor deze verwerkingen.
                </Paragraph>

                <Heading as="h2">Cookies, of vergelijkbare technieken, die wij gebruiken</Heading>
                <Paragraph>
                    Veterinair Mobiele CT-scan gebruikt alleen technische en functionele cookies. En analytische
                    cookies
                    die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste
                    bezoek
                    aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij
                    gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze
                    zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld uw
                    voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden
                    voor
                    cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast
                    kunt
                    u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.
                </Paragraph>

                <Paragraph>
                    <strong>Google Analytics</strong><br/>
                    Via onze website worden cookies geplaatst van het Amerikaanse bedrijf Google, als deel van de
                    “Analytics”-dienst. Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over
                    hoe
                    bezoekers de website gebruiken. Deze verwerker is mogelijk verplicht op grond van geldende wet-
                    en
                    regelgeving inzage te geven in deze gegevens. Wij hebben Google niet toegestaan de verkregen
                    analytics-informatie te gebruiken voor andere Google diensten.
                </Paragraph>

                <Paragraph>
                    <strong>Cookies van derde partijen</strong><br/>
                    In het geval dat software van derde partijen gebruik maken van cookies is dit vermeld in deze
                    privacyverklaring.
                </Paragraph>

                <Heading as="h2">Gegevens inzien, aanpassen of verwijderen</Heading>
                <Paragraph>
                    U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast
                    heeft
                    u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te
                    maken tegen de verwerking van uw persoonsgegevens door Veterinair Mobiele CT-scan en heeft u het
                    recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de
                    persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u
                    genoemde organisatie, te sturen.
                </Paragraph>
                <Paragraph>
                    U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw
                    persoonsgegevens
                    of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens
                    sturen naar info@mobieledierenscan.nl.
                </Paragraph>
                <Paragraph>
                    Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van
                    uw
                    identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine
                    readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en
                    Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel
                    mogelijk,
                    maar binnen vier weken, op uw verzoek.
                </Paragraph>
                <Paragraph>
                    Veterinair Mobiele CT-scan wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht
                    in te
                    dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende
                    link:
                    https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
                </Paragraph>

                <Heading as="h2">Hoe wij persoonsgegevens beveiligen</Heading>
                <Paragraph>
                    Veterinair Mobiele CT-scan neemt de bescherming van uw gegevens serieus en neemt passende
                    maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en
                    ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd
                    zijn
                    of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via
                    info@mobieledierenscan.nl
                </Paragraph>

                <Heading as="h2">MailChimp</Heading>
                <Paragraph>
                    Wij versturen onze e-mail nieuwsbrieven met MailChimp. MailChimp zal uw naam en e-mailadres
                    nooit
                    voor eigen doeleinden gebruiken. Onderaan elke e-mail die geautomatiseerd via onze website is
                    verzonden ziet u de ‘unsubscribe’ link. U ontvangt onze nieuwsbrief dan niet meer. Uw
                    persoonsgegevens worden door MailChimp beveiligd opgeslagen. MailChimp maakt gebruik van cookies
                    en
                    andere internettechnologieën die inzichtelijk maken of e-
                    mails worden geopend en gelezen. MailChimp behoudt zich het recht voor om uw gegevens te
                    gebruiken
                    voor
                    het
                    verder verbeteren van de dienstverlening en in het kader daarvan informatie met
                    derden te delen.
                </Paragraph>

                <Heading as="h2">Facturatie en boekhouden</Heading>
                <Paragraph>
                    <strong>E Boekhouden</strong><br/>
                    Voor het bijhouden van onze administratie en boekhouding maken wij gebruik van de diensten van E
                    Boekhouden. Wij delen uw naam, adres en woonplaatsgegevens en details met betrekking tot onze
                    dienstverlening. Deze gegevens worden gebruikt voor het administreren van verkoopfacturen. Uw
                    persoonsgegevens worden beschermd verzonden en opgeslagen.
                    E Boekhouden is tot geheimhouding verplicht en zal uw gegevens vertrouwelijk behandelen. E
                    Boekhouden gebruikt uw persoonsgegevens niet voor andere doeleinden dan hierboven beschreven.
                </Paragraph>

                <Heading as="h2">Uw rechten</Heading>
                <Paragraph>
                    Op grond van de geldende Nederlandse en Europese wetgeving heeft u als betrokkene bepaalde
                    rechten met betrekking tot de persoonsgegevens die door of namens ons worden verwerkt. Wij
                    leggen
                    u hieronder uit welke rechten dit zijn en hoe u zich op deze rechten kunt beroepen.
                </Paragraph>

                <Paragraph>
                    In beginsel sturen wij om misbruik te voorkomen afschriften en kopieën van uw gegevens enkel
                    naar
                    uw bij ons reeds bekende e-mailadres. In het geval dat u de gegevens op een ander e-mailadres of
                    bijvoorbeeld per post wenst te ontvangen, zullen wij u vragen zich te legitimeren. Wij houden
                    een
                    administratie bij van afgehandelde verzoeken, in het geval van een vergeetverzoek administreren
                    wij
                    geanonimiseerde gegevens. Alle afschriften en kopieën van gegevens ontvangt u in de machine
                    leesbare gegevensindeling die wij binnen onze systemen hanteren. U heeft te allen tijde het
                    recht om
                    een klacht in te dienen bij de Autoriteit Persoonsgegevens als u vermoedt dat wij uw
                    persoonsgegevens op een verkeerde manier gebruiken.
                </Paragraph>

                <Heading as="h2">Inzagerecht</Heading>
                <Paragraph>
                    U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw
                    persoon of daartoe herleidbaar zijn, in te zien. U kunt een verzoek met die strekking doen aan
                    onze
                    contactpersoon voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als
                    uw verzoek wordt ingewilligd sturen wij u op het bij ons bekende e-mailadres een kopie van alle
                    gegevens met een overzicht van de verwerkers die deze gegevens onder zich hebben, onder
                    vermelding van de categorie waaronder wij deze gegevens hebben opgeslagen.
                </Paragraph>

                <Heading as="h2">Rectificatierecht</Heading>
                <Paragraph>
                    U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw
                    persoon of daartoe herleidbaar zijn, te laten aanpassen. U kunt een verzoek met die strekking
                    doen
                    aan onze contactpersoon voor privacy zaken. U ontvangt dan binnen 30 dagen een reactie op uw
                    verzoek. Als uw verzoek wordt ingewilligd sturen wij u op het bij ons bekende e-mailadres een
                    bevestiging dat de gegevens zijn aangepast.
                </Paragraph>

                <Heading as="h2">Recht op beperking van de verwerking</Heading>
                <Paragraph>
                    U heeft altijd het recht om de gegevens die wij (laten) verwerken die betrekking hebben op uw
                    persoon
                    of daartoe herleidbaar zijn, te beperken. U kunt een verzoek met die strekking doen aan onze
                    contactpersoon voor privacy zaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als
                    uw verzoek wordt ingewilligd sturen wij u op het bij ons bekende e-mailadres een bevestiging dat
                    de
                    gegevens tot u de beperking opheft niet langer worden verwerkt.
                </Paragraph>

                <Heading as="h2">Recht op overdraagbaarheid</Heading>
                <Paragraph>
                    U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw
                    persoon of daartoe herleidbaar zijn, door een andere partij te laten uitvoeren. U kunt een
                    verzoek
                    met
                    die strekking doen aan onze contactpersoon voor privacy zaken. U ontvangt dan binnen 30 dagen
                    een
                    reactie op
                    uw verzoek. Als uw verzoek wordt ingewilligd sturen wij u op het bij ons bekende e-
                    mailadres afschriften of kopieën van alle gegevens over u die wij hebben verwerkt of in opdracht
                    van
                    ons
                    door andere verwerkers of derden zijn verwerkt. Naar alle waarschijnlijkheid
                    kunnen wij in een dergelijk geval de dienstverlening niet langer voortzetten, omdat de veilige
                    koppeling van databestanden dan niet langer kan worden gegarandeerd.
                </Paragraph>

                <Heading as="h2">Recht van bezwaar en overige rechten</Heading>
                <Paragraph>
                    U heeft in voorkomende gevallen het recht bezwaar te maken tegen de verwerking van uw
                    persoonsgegevens door of in opdracht van Veterinair Mobiele CT-scan. Als u bezwaar maakt zullen
                    wij
                    onmiddellijk de gegevensverwerking staken in afwachting van de afhandeling van uw bezwaar. Is uw
                    bezwaar gegrond dat zullen wij afschriften en/of kopieën van gegevens die wij (laten) verwerken
                    aan
                    u ter beschikking stellen en daarna de verwerking blijvend staken. U heeft bovendien het recht
                    om
                    niet
                    aan geautomatiseerde individuele besluitvorming of profiling te worden onderworpen. Wij
                    verwerken uw gegevens niet op zodanige wijze dat dit recht van toepassing is. Bent u van mening
                    dat
                    dit wel zo is, neem dan contact op met onze contactpersoon voor privacy zaken.
                </Paragraph>

                <Heading as="h2">Wijzigingen in het privacy beleid</Heading>
                <Paragraph>
                    Wij behouden te allen tijde het recht ons privacy beleid te wijzigen. Op deze pagina vindt u
                    echter
                    altijd
                    de meest recente versie. Als het nieuwe privacy beleid gevolgen heeft voor de wijze waarop wij
                    reeds
                    verzamelde gegevens met betrekking tot u verwerken, dan brengen wij u daarvan per e-mail op de
                    hoogte.
                </Paragraph>

                <Paragraph>
                    Veterinair Mobiele CT-scan
                    Ooster Vlaerdinge 73
                    1704MX Heerhugowaard
                    +31 6 82 29 55 77
                    info@mobieledierenscan.nl
                </Paragraph>
            </Block>
        </Page>
    </>
);
