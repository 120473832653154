import React from "react";
import GoogleMapReact from "google-map-react";

import {Marker} from "./style";

const GoogleMaps = ({locations, defaultCenter, defaultZoom}) => (
    <GoogleMapReact
        bootstrapURLKeys={{key: 'AIzaSyABHBU6JLGJfNlX_kboM9olwppgmjh-DGM'}}
        defaultCenter={defaultCenter || {lat: 52.685330, lng: 4.797460}}
        defaultZoom={defaultZoom || 12}
    >
        {locations && locations().map((location) => {
            const websiteLink = `//${location.website}`;
            return (
                <Marker
                    key={location.id}
                    px={0}
                    lat={location.lat}
                    lng={location.lng}
                >
                    {location.website && (
                        <a href={websiteLink} rel="noopener noreferrer">{location.label}</a>
                    )}
                </Marker>
            )
        })}
    </GoogleMapReact>
);

export default GoogleMaps;
