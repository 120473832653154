import styled, {css} from 'styled-components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Mask = styled('div')(props => css`
    position: relative;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid ${props.theme.colors.background};
    background-color: ${props.theme.colors.grey};
    white-space: nowrap;
    
    &:before {
        content: '';
        position: relative;
        display: block;
        padding-bottom: 45%;
    }
    
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-image: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.2) 100%);
    }
`);

export const Cover = styled('div')(css`
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1);
`);

export const Wrapper = styled('div')(css`
    position: relative;
    height: 100%;
    width: 100%;
    transform: translateX(0px);
    transition: transform ease-out 0.45s;
`);

export const Info = styled('div')(props => css`
    ${props.theme.fonts.light};
    
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    background-color: rgba(0,0,0,0.5);
    color: ${props.theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    font-size: 200%;
    z-index: 2;
    
    ${Wrapper}:hover & {
        opacity: 1;
        pointer-events: initial;
        cursor: pointer;
    }
`);

const NavigationTemplate = props => css`
    position: absolute;
    top: 50%;
    z-index: 999;
    color: ${props.theme.colors.white}
    font-size: ${props.theme.fontSize.xl}
    cursor: pointer;
`;

export const LeftArrow = styled(FontAwesomeIcon)(props => css`
    ${NavigationTemplate(props)}
    left: 25px;
`);

export const RightArrow = styled(FontAwesomeIcon)(props => css`
    ${NavigationTemplate(props)}
    right: 25px;
`);
