import React from 'react';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {setOwner, setVeterinarian} from "../Store/Actions/app";

import HeroBanner from "../Components/HeroBanner";
import HeroBannerImage from "../Components/HeroBanner/HeroBannerImage";
import HeroBannerTestimonial from "../Components/HeroBannerTestimonial";
import ServiceReminder from "../Components/ServiceReminder";

import Container from "../StyledComponents/Container";

const Page = (props) => {


    return (
        <>
            {props.heroBannerImages.length ? (
                <HeroBanner items={props.heroBannerImages} infoText="Neem een kijkje achter de schermen">
                    {props.heroBannerImages.map((imageUrl, index) => (
                        <HeroBannerImage key={index} url={imageUrl} />
                    ))}
                </HeroBanner>
            ): null}
            {props.testimonials.length ? (
                <HeroBanner items={props.testimonials} infoText="Wat vertellen klanten over ons?">
                    {props.testimonials.map((testimonial, index) => (
                        <HeroBannerTestimonial key={index} testimonial={testimonial} />
                    ))}
                </HeroBanner>
            ): null}
            <ServiceReminder/>

            <Container pt={['xs', 'xs', 'xs', 'xl']} whiteBackground>
                {props.children}
            </Container>
        </>
    );
};

Page.propTypes = {
    heroBannerImages: PropTypes.array,
    testimonials: PropTypes.array,

    children: PropTypes.node.isRequired,
    onOwner: PropTypes.bool.isRequired,
    onVeterinarian: PropTypes.bool.isRequired,
    setOwner: PropTypes.func.isRequired,
    setVeterinarian: PropTypes.func.isRequired,
};

Page.defaultProps = {
    heroBannerImages: [],
    testimonials: [],
};

export default connect(
    state => ({
        onOwner: state.get('onOwner'),
        onVeterinarian: state.get('onVeterinarian'),
    }),
    dispatch => bindActionCreators({
        setOwner,
        setVeterinarian,
    }, dispatch),
)(Page);
