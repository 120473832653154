import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types';

import Page from "../Page";

import PersonBadge from "../../Components/PersonBadge";
import ContactForm from "../../Components/ContactForm";

import {Col, Row} from "../../StyledComponents/Grid";
import Block from "../../StyledComponents/Block";
import {Heading, Paragraph} from "../../StyledComponents/Typography";
import Image from "../../StyledComponents/Image";

import imageNathalie from "../../Assets/Images/nathalie-schigt.png";
import {index as HeroBannerImages} from "../../Assets/HeroBannerImages";

const Index = () => (
    <>
        <Helmet>
            <title>CT-Scan voor uw huisdier - Mobieledierenscan.nl</title>
            <meta charSet="utf-8"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description"
                  content="Bij uw eigen dierenarts maken wij een ct-scan van uw hond, kat, vogel of knaagdieren."/>
            <meta name="keywords"
                  content="CT Scan, Onderzoek, Diagnostiek, Hond, Kat, Vogel, Knaagdier, Reptielen, Dierenarts"/>
            <link rel="canonical" href="https://mobieledierenscan.nl/diereneigenaar"/>
        </Helmet>

        <Page heroBannerImages={HeroBannerImages}>
            <Row flexDirection={['column', 'column', 'column', 'row']}>
                <Col width={[1, 1, 1, 2 / 3]} mr={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]} pr={[0, 0, 0, 'xxl']}>
                    <Block width={1} mb="md">
                        <Heading as="h1">
                            Wat is Veterinair Mobiele CT-scan?
                        </Heading>
                        <Paragraph>
                            Veterinair Mobiele CT-scan is een kleine vrachtwagen voorzien van een CT-scan. Door
                            het mobiele aspect kunnen wij bij iedere praktijk een scan uitvoeren. Dierenartsen
                            kunnen 24/7 gebruik maken van onze dienst waardoor uw dier snel gediagnostiseerd kan
                            worden.
                        </Paragraph>

                        <Heading as="h2" fontSize="md">
                            Waarom gebruik maken van Veterinair Mobiele CT-scan?
                        </Heading>
                        <Paragraph>
                            Voorafgaand aan een operatie of andere behandeling is het zeer belangrijk om goede
                            diagnostiek uit te voeren. Zo kan de chirurg uw dier zo accuraat mogelijk opereren.
                            Dit is belangrijk met het oog op een goed herstel.
                        </Paragraph>
                        <Paragraph>
                            Echografie en röntgen kunnen gebruikt worden voor een goede diagnose, echter deze
                            geven vaak niet voldoende informatie. Daarbij is onder andere een CT-scan
                            aangewezen. U als eigenaar bent dan vaak genoodzaakt om doorverwezen te worden naar
                            één van de weinige specialistische klinieken in Nederland. Ziet u reizen met uw
                            zieke dier niet zitten of wordt u liever geholpen door uw eigen vertrouwde
                            dierenarts? Dan kunt u gebruik maken van Veterinair Mobiele CT-scan.
                        </Paragraph>
                    </Block>
                    <Block width={1} mb="md">
                        <Heading as="h1" fontSize="md">
                            Hoe werkt een scan?
                        </Heading>
                        <Paragraph>
                            Uw huisdier wordt door uw eigen dierenarts in slaap gebracht en overgedragen aan
                            Veterinair Mobiele CT-scan. Het maken van een CT-scan duurt, afhankelijk van het te
                            scannen gebied, ongeveer 30 tot 60 seconden. Het beeldmateriaal wordt beoordeeld
                            door een radioloog en uw huisdier kan rustig uitslapen bij uw eigen dierenarts, of
                            wordt direct verder geholpen indien nodig/mogelijk.
                        </Paragraph>
                        <Paragraph>
                            Computer Tomografie, afgekort CT, is een diagnostische methode waarbij door middel
                            van röntgenstralen, 2D- en 3D- beelden van het lichaam worden gemaakt. Het is een
                            snelle, pijnloze, niet-ingrijpende en accurate techniek om afwijkingen bij onder
                            meer dieren in beeld te brengen. CT is dé techniek bij uitstek om botstructuren te
                            visualiseren. Daarnaast wordt CT ook toegepast om weke delen structuren (zoals
                            buikorganen en hersenen) in beeld te brengen.
                        </Paragraph>

                        <Heading as="h2" fontSize="md">
                            Wat kost het gebruik van Veterinair Mobiele CT-scan?
                        </Heading>
                        <Paragraph>
                            De kosten van de CT-scan rekent u af bij uw eigen dierenarts. Hij/zij zal u
                            informeren over de kosten.
                            Heeft u een huisdierenverzekering? Bij veel verzekeringsmaatschappijen worden de
                            kosten van een CT-scan vergoed. Neem met uw eigen verzekering contact op om dit na
                            te vragen.
                        </Paragraph>
                    </Block>
                    <Block dark width={1}>
                        <Heading>Contact</Heading>
                        <Paragraph>
                            Wilt u graag meer informatie ontvangen? Vul onderstaand formulier in om contact op te
                            nemen.
                        </Paragraph>
                        <ContactForm/>
                    </Block>
                </Col>
                <Col width={[1, 1, 1, 1 / 3]} ml={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <PersonBadge>
                        <Image
                            float="right"
                            width={['40%', '40%', '20%', '30%']}
                            ml="md"
                            src={imageNathalie}
                        />
                        <Heading as="h1">Wie ben ik en wat doe ik?</Heading>
                        <Paragraph>
                            Ik ben dierenarts Nathalie Leenheer-Schigt, afgestudeerd in 2011 aan de Universiteit
                            Gent, België. Vanaf mijn studie heb ik al een grote passie gehad voor weke delen en
                            orthopedische chirurgie. In de afgelopen jaren heb ik mij sterk ontwikkeld op gebied
                            van chirurgisch ingrijpen bij knie problemen, botbreuken en ellenboog operaties.
                        </Paragraph>
                    </PersonBadge>
                </Col>
            </Row>
        </Page>
    </>
);

Index.propTypes = {
    page: PropTypes.object,
};

Index.defaultProps = {
    page: {},
};

export default Index;
