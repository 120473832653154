import styled, {css} from "styled-components";
import {width, space, fontSize} from "styled-system";

export const Button = styled('button')(props => css`
    ${props.theme.fonts.trajan};

    background-color: ${props.theme.colors.primary};
    border: 0;
    cursor: pointer;
    color: ${props.theme.colors.white};
    border-radius: ${props.theme.space.sm};
    padding: ${props.theme.space.md};

    &:focus {
        outline: none;
    }
    
    &:visited { 
        color: ${props.theme.colors.white};    
    }
`, [space, width, fontSize]);
