import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import {Form, Input, SendButton} from "../../StyledComponents/Form";

import {Label, NewsletterContainer} from "./style";

const SubscribeForm = ({status, message, onValidated}) => {
    let email;
    const submit = () => email && email.value.indexOf("@") > -1 && onValidated({
        EMAIL: email.value,
        'b_29186aaf256ac58c9f5f6c224_a8fa38e737': '',
    });

    const disabled = status !== 'success' && status !== 'sending';

    return (
        <>
            <Label>
                {status === 'error' && (<span dangerouslySetInnerHTML={{__html: message}}/>)}
                {status === 'success' && (<span dangerouslySetInnerHTML={{__html: message}}/>)}
                {status === 'sending' && 'Je aanvraag word verwerkt...'}
                {!status && 'Meld je vrijblijvend aan voor onze maandelijkse nieuwsbrief met cursussen en beeldmateriaal!'}
            </Label>
            <Form>
                <Input white disabled={!disabled} ref={node => (email = node)} type="email"
                       placeholder="Uw e-mailadres..." name="EMAIL"/>
                <input type="hidden" name="b_29186aaf256ac58c9f5f6c224_a8fa38e737"/>
                <SendButton disabled={!disabled} onClick={submit}>Verstuur</SendButton>
            </Form>
        </>
    );
};

const Newsletter = () => (
    <NewsletterContainer>
        <MailchimpSubscribe
            url='https://mobieledierenscan.us20.list-manage.com/subscribe/post?u=29186aaf256ac58c9f5f6c224&amp;id=a8fa38e737'
            render={({subscribe, status, message}) => (
                <SubscribeForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
        />
    </NewsletterContainer>
);

export default Newsletter;
