export default {
    primary: '#1B2C5D',
    secondary: '#496DAE',
    secondaryLighter: '#D1E2F5',
    background: '#DBE7F5',
    black: '#000000',
    white: '#FFFFFF',
    grey: '#CCCCCC',
    lightGrey: '#eeeeee',
};
