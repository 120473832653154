import React from "react";
import {NavLink} from "react-router-dom";

import {SelectionButton} from "./style";
import Logo from "../../StyledComponents/Logo";
import {Col, Row} from "../../StyledComponents/Grid";
import Container from "../../StyledComponents/Container";

export default ({setVeterinarian, setOwner}) => (
    <Container>
        <Row width={1} justifyContent="center" mb="lg">
            <Logo width={['75%', '75%', '75%', '100%']}/>
        </Row>
        <Row width={1} flexDirection={['column', 'column', 'column', 'row']}>
            <Col width={[1, 1, 1, 1 / 2]} pr={[0, 0, 0, 'sm']} pb="md">
                <SelectionButton as={NavLink} to="/dierenarts" onClick={() => setVeterinarian()}>
                    Ik ben dierenarts
                </SelectionButton>
            </Col>
            <Col width={[1, 1, 1, 1 / 2]} pl={[0, 0, 0, 'sm']} pb="md">
                <SelectionButton as={NavLink} to="/diereneigenaar" onClick={() => setOwner()}>
                    Ik ben diereneigenaar
                </SelectionButton>
            </Col>
        </Row>
    </Container>
);
