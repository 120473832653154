import React, {useState} from "react";
import axios from "axios";

import {Form, Input, SubmitButton} from "../../StyledComponents/Form";
import {Row} from "../../StyledComponents/Grid";

import {Label} from "./style";

const AppointmentForm = () => {
    const [sendSuccessful, setSendSuccessful] = useState(false);
    const [sendingMail, setSendingMail] = useState(false);
    const [messages, setMessages] = useState(null);
    const [showAllFields, setShowAllFields] = useState(null);

    const clinicRef = React.createRef();
    const nameRef = React.createRef();
    const emailRef = React.createRef();
    const phoneRef = React.createRef();
    const timeRef = React.createRef();
    const dateRef = React.createRef();

    const onSubmit = () => {
        setSendingMail(true);
        setMessages(null);

        axios.post('/appointment.php', {
            clinic: clinicRef.current && clinicRef.current.value,
            name: nameRef.current && nameRef.current.value,
            date: dateRef.current && dateRef.current.value,
            time: timeRef.current && timeRef.current.value,
            phone: phoneRef.current && phoneRef.current.value,
            from: emailRef.current && emailRef.current.value,
            subject: `Afspraak maken`,
        })
            .then((response) => {
                    setSendingMail(false);
                    setSendSuccessful(true);
                    setMessages(response.data.messages);
                },
                (error) => {
                    setSendingMail(false);
                    setSendSuccessful(false);
                    setMessages(error.response.data.message);
                })
            .catch((error) => {
                setSendingMail(false);
                setSendSuccessful(false);
                setMessages(error.response.data.message);
            });
    };

    return sendSuccessful ? (
        <Row>
            {messages}
        </Row>
    ) : (
        <Form flexDirection="column">
            <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                <Label required width={[1, 1, 1, 2 / 5]}>Welke kliniek?</Label>
                <Input ref={clinicRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]}
                       name="nameClinic" onClick={() => setShowAllFields(true)} />
            </Row>
            {showAllFields && (
                <>
                    <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                        <Label required width={[1, 1, 1, 2 / 5]}>Uw naam:</Label>
                        <Input ref={nameRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]} name="name"/>
                    </Row>
                    <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                        <Label required width={[1, 1, 1, 2 / 5]}>Uw e-mail:</Label>
                        <Input ref={emailRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]} name="email"
                               type="email"/>
                    </Row>
                    <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                        <Label required width={[1, 1, 1, 2 / 5]}>Uw telefoonnr:</Label>
                        <Input ref={phoneRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 3 / 5]} name="phone"
                               type="phone"/>
                    </Row>
                    <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                        <Label required width={[1, 1, 1, 2 / 5]}>Gewenste tijd & datum:</Label>
                        <Input ref={timeRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 1 / 5]} name="time"
                               type="time" step={300}/>
                        <Input ref={dateRef} disabled={!!sendingMail} p="sm" width={[1, 1, 1, 2 / 5]} name="date"
                               type="date"/>
                    </Row>
                </>
            )}
            <Row mb="sm" flexDirection={['column', 'column', 'column', 'row']}>
                <Label width={[1, 1, 1, 3 / 5]}>Velden met een * zijn verplicht</Label>
                <SubmitButton disabled={!!sendingMail} onClick={onSubmit} width={[1, 1, 1, 2 / 5]}>
                    Verstuur
                </SubmitButton>
            </Row>
            {messages && (
                <Row>
                    {messages}
                </Row>
            )}
        </Form>
    );
};

export default AppointmentForm;
