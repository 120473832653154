import React, {createRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {animateScroll as scroll} from 'react-scroll';

import {Cover, Info, LeftArrow, Mask, RightArrow, Wrapper} from './style';

const HeroBanner = (props) => {
    const [isInitialLoad, toggleInitialLoad] = useState(true);
    const [scrollPos, setScrollTop] = useState(0);
    const [windowWidth, setWindowWidth] = useState(0);
    const [sliderActive, setSliderActive] = useState(false);

    /** Slider related **/
    const wrapperRef = createRef();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [translateValue, setTranslateValue] = useState(0);

    const setInitialScrollPos = (pos) => {
        if (typeof window !== 'undefined' && windowWidth > 768) {
            window.scroll(0, pos);
            toggleInitialLoad(false);
        }
    };

    const closedHeight = windowWidth * 0.2;
    const openedHeight = windowWidth * 0.40;
    const openedClosedDistance = openedHeight - closedHeight;

    const scrollUnit = scrollPos / openedHeight;
    const scrollPercentage = scrollUnit > 1 ? 40 : scrollUnit * 40;
    const menuOffsetPercentage = scrollUnit * 40;

    isInitialLoad && (openedClosedDistance !== 0) && setInitialScrollPos(openedClosedDistance);

    const getScrollTop = () => {
        if (typeof window !== 'undefined') {
            const {scrollTop} = (document.scrollingElement || document.documentElement);
            setScrollTop(scrollTop);
        }
    };

    const getWindowWidth = () => {
        if (typeof window !== 'undefined') {
            const width = Math.max(
                window.innerWidth || 0,
                document.documentElement.clientWidth || 0,
                document.body.clientWidth || 0,
            );

            setWindowWidth(width);
        }
    };

    const sliderWidth = () => wrapperRef.current.clientWidth;
    const goToPrevSlide = () => {
        if (currentIndex === 0) {
            setCurrentIndex(props.items.length - 1);
            setTranslateValue(translateValue + -(sliderWidth() * (props.items.length - 1)));

            return;
        }

        setCurrentIndex(currentIndex - 1);
        setTranslateValue(translateValue - -sliderWidth());
    };

    const goToNextSlide = () => {
        if (currentIndex === props.items.length - 1) {
            setCurrentIndex(0);
            setTranslateValue(0);

            return;
        }

        setCurrentIndex(currentIndex + 1);
        setTranslateValue(translateValue + -(sliderWidth()));
    };

    if (props.slider) {
        if (!sliderActive && menuOffsetPercentage <= 5) {
            setSliderActive(true);
        }

        if (sliderActive && menuOffsetPercentage > 5) {
            setSliderActive(false);
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            getScrollTop();
            getWindowWidth();

            window.addEventListener('scroll', getScrollTop);
            window.addEventListener('resize', getWindowWidth);

            return () => {
                window.removeEventListener('scroll', getScrollTop);
                window.removeEventListener('resize', getWindowWidth);
            };
        }

        setInterval(() => {
            goToNextSlide();
        }, 1000);

        return false;
    }, []);


    return (
        <Mask>
            <Cover
                style={{
                    transform: `translateY(${scrollPercentage}%) scale(${(scrollUnit / 10) + 1})`,
                    top: `${menuOffsetPercentage}px`,
                }}
            >
                <Wrapper
                    ref={wrapperRef} translateValue={translateValue}
                    style={{
                        transform: `translateX(${translateValue}px)`,
                    }}
                >
                    {props.slider && !sliderActive && <Info onClick={scroll.scrollToTop}>{props.infoText}</Info>}
                    {props.children}
                </Wrapper>

                {props.slider && sliderActive && (
                    <>
                        <LeftArrow onClick={goToPrevSlide} icon="chevron-left"/>
                        <RightArrow onClick={goToNextSlide} icon="chevron-right"/>
                    </>
                )}
            </Cover>
        </Mask>
    );
};

HeroBanner.propTypes = {
    infoText: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    children: PropTypes.node,
    slider: PropTypes.bool,
};

HeroBanner.defaultProps = {
    children: null,
    slider: true,
};

export default HeroBanner;
