import {applyMiddleware, compose, createStore} from "redux";
import thunkMiddleware from 'redux-thunk';
import appReducer from "./Reducers/app";

const createStoreWithMiddleware = compose(
    applyMiddleware(thunkMiddleware),
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
)(createStore);

export default createStoreWithMiddleware(appReducer);
