import {fromJS} from 'immutable';

const initialState = fromJS({
    loading: false,
    onOwner: false,
    onVeterinarian: false,
});

export default (state = initialState, action) => {
    window.scrollTo(0, 0);

    switch (action.type) {
        case 'SET_OWNER':
            return state
                .set('loading', false)
                .set('onOwner', true)
                .set('onVeterinarian', false);
        case 'SET_VETERINARIAN':
            return state
                .set('loading', false)
                .set('onVeterinarian', true)
                .set('onOwner', false);
        case 'RESET_STATE':
            return state
                .set('loading', false)
                .set('onVeterinarian', false)
                .set('onOwner', false);
        case 'SET_LOADING':
            return state
                .set('loading', action.status);
        default:
            return state;
    }
};
