import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from 'styled-components';
import axios from 'axios';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";

import store from './Store';
import theme from './Theme';
import App from "./App";

const {GlobalStyling} = theme;

axios.baseURL = 'https://mobieledierenscan.v2.local/';

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <>
                    <GlobalStyling/>
                    <App/>
                </>
            </BrowserRouter>
        </ThemeProvider>
    </Provider>,
    document.getElementById('vmct'),
);
