export default {
    xs: '75px',
    sm: '100px',
    md: '125px',
    lg: '150px',
    xl: '175px',
    xxl: '200px',
    xxxl: '250px',
    all: '100%',
}
