import React, {useState} from 'react';
import {Helmet} from "react-helmet";

import Page from "../Page";

import AppointmentForm from "../../Components/AppointmentForm";

import Block from "../../StyledComponents/Block";
import {Col, Row} from "../../StyledComponents/Grid";
import {Heading, Paragraph} from "../../StyledComponents/Typography";
import {Item, List} from "../../StyledComponents/List";

import {aboutUs as HeroBannerImages} from "../../Assets/HeroBannerImages";
import ReadMore from "../../Components/ReadMore";

const AboutUs = () => {
    const [historyOpen, setHistoryOpen] = useState(false);

    return (
        <>
            <Helmet>
                <title>Over ons - Mobieledierenscan.nl</title>
                <meta charSet="utf-8"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description"
                      content="Zowel in de veterinaire als in de humane sector is er opgemerkt dat de minst invasieve gezondheidszorg alsmaar belangrijker wordt."/>
                <meta name="keywords"
                      content="CT-scan, Informatie, Dierenarts, Zorg, Diagnose, Kliniek, Operatie, Collega, Radioloog"/>
                <link rel="canonical" href="https://mobieledierenscan.nl/dierenarts/over-ons"/>
            </Helmet>

            <Page heroBannerImages={HeroBannerImages}>
                <Row flexDirection={['column', 'column', 'column', 'row']}>
                    <Col width={[1, 1, 1, 1 / 2]} mr={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                        <Block width={1} mb="md">
                            <Heading as="h1">Het ontstaan van Veterinair Mobiele CT-scan</Heading>
                            <Paragraph>
                                Zowel in de veterinaire als in de humane sector is er opgemerkt dat de minst
                                invasieve gezondheidszorg alsmaar belangrijker wordt (zonder het lichaam binnen te
                                hoeven gaan een diagnose kunnen stellen). Daarnaast is er de afgelopen jaren een
                                trend dat de zorg voor dieren steeds naar een hoger niveau wordt gebracht.
                            </Paragraph>
                            <Paragraph>
                                Dit heeft een aantal oorzaken:
                            </Paragraph>
                            <List>
                                <Item>
                                    Specialistische klinieken, waar wel uitgebreide zorg geboden kan worden,
                                    zijn er nog te weinig in Nederland.
                                </Item>
                                <Item>
                                    De eisen van de diereigenaren worden groter dan wat een gemiddelde praktijk
                                    kan bieden
                                </Item>
                            </List>
                            <Paragraph>
                                Daarnaast spelen er nog een aantal factoren:
                            </Paragraph>
                            <List>
                                <Item>
                                    De vraag naar goede tweedelijns diergeneeskunde is groot maar het aanbod is
                                    nog te weinig op de markt.
                                </Item>
                                <Item>
                                    Het budget van de eigenaren is vaak niet toereikend om direct door te
                                    stappen naar één van de specialistenklinieken.
                                </Item>
                            </List>
                            <Paragraph>
                                Bovendien wil men liever eerst goede diagnostiek voordat er pas chirurgisch
                                ingegrepen wordt. Hoewel de preventieve zorg steeds belangrijker wordt blijft
                                Nederland daar ten opzichte van andere landen in achter. Tijdens een bezoek van
                                Nathalie aan Engeland is het idee gecreëerd om een mobiele CT-scan in Nederland op
                                de markt te brengen om in te spelen op de huidige trend.
                            </Paragraph>
                            {!historyOpen && <ReadMore onClick={() => setHistoryOpen(true)} status={historyOpen}/>}
                            {historyOpen && (
                                <>
                                    <Paragraph>
                                        Binnen de humane sector is een mobiele onderzoekunit een normaal gegeven. Deze
                                        units worden in steden geplaatst voor diverse onderzoeken (denk bijvoorbeeld aan
                                        borstkankeronderzoeken). Hoewel ieder ziekenhuis een CT-scan heeft, worden deze
                                        mobiele oplossingen aangeboden om mensen preventief te onderzoeken vanuit het
                                        standpunt ‘minder snijden, meer diagnostiek’.
                                    </Paragraph>
                                    <Paragraph>
                                        Het beschikbaar maken van een CT-scan voor iedere praktijk en dus iedere patiënt
                                        in de kleine huisdieren sector zou mogelijk kunnen zijn in een mobiel concept.
                                        Na het vinden van de juiste partijen hebben wij het concept verder kunnen
                                        uitwerken. Een kleine vrachtwagen ombouwt met lood, zodat er aan
                                        stralingsvoorwaarden voldaan kan worden, bestuurd door Nathalie. Toegankelijk
                                        voor dieren met een gewichtsklasse tot 300 kg. Een service die dag en nacht
                                        beschikbaar is en tot aan de voordeur van de praktijk kan komen.
                                    </Paragraph>
                                    <ReadMore onClick={() => setHistoryOpen(false)} status={historyOpen}/>
                                </>
                            )}
                        </Block>
                    </Col>
                    <Col width={[1, 1, 1, 1 / 2]} ml={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                        <Block width={1} mb="md">
                            <Heading as="h1">
                                Met wie werk ik samen?
                            </Heading>

                            <Heading as="h2" fontSize="md">
                                Robbert Leenheer
                            </Heading>
                            <Paragraph mb="md">
                                Afgestudeerd in 2012, Bedrijfskunde aan de Erasmus Universiteit Rotterdam. Als
                                bedrijfskundige houdt Robbert zich bezig met alle zaken die niet direct gerelateerd
                                zijn aan de uitvoering van diergeneeskunde. Robbert is per telefoon of e-mail het
                                aanspreekpunt van Veterinair Mobiele CT-scan. Wanneer u ons belt zult u
                                hoogstwaarschijnlijk Robbert aan de telefoon krijgen, waarbij hij met u de juiste
                                afspraak kan inplannen en een zo snel mogelijke service kan verlenen.
                            </Paragraph>

                            <Heading as="h2" fontSize="md">
                                Kaatje Kromhout, radioloog
                            </Heading>
                            <Paragraph>
                                Studeerde in 2008 als dierenarts af aan de Universiteit Gent. Aansluitend startte
                                zij haar internship Medische Beeldvorming aan de vakgroep Medische Beeldvorming van
                                de Huisdieren en Orthopedie van de Kleine Huisdieren van de Faculteit
                                Diergeneeskunde (Merelbeke, België). Sinds 2009 zorgt zij daar voor de dagelijkse
                                CT- en MRI-onderzoeken en protocollering. Ook is zij actief op de afdeling
                                scintigrafie, echografie en RX. In januari 2012 ging zij bijkomend als teleradioloog
                                bij meerdere klinieken in binnen- en buitenland aan de slag. Begin 2016 behaalde ze
                                de graad van doctor in de diergeneeskundige wetenschappen met haar
                                doctoraatsonderzoek getiteld “ Comparison of magnetic resonance imaging and computed
                                tomography for the detection of specific brain and cervical spine abnormalities in
                                small animals”. Ze is auteur en co-auteur van talrijke publicaties in nationale en
                                internationale wetenschappelijke tijdschriften.
                            </Paragraph>
                        </Block>
                        <Block dark width={1}>
                            <Heading color="white">Afspraak maken?</Heading>
                            <Paragraph>
                                Bent u dierenarts en wilt u een afspraak maken voor uw patiënt, maak dan gebruik van
                                onderstaande.
                            </Paragraph>
                            <AppointmentForm/>
                        </Block>

                    </Col>
                </Row>
            </Page>
        </>
    );
}

export default AboutUs;
