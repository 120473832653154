import React from "react";
import PropTypes from "prop-types";

import {Information, Item} from "./style";

const AccordionSection = ({isOpen, label, onClick, children}) => {
    const onItemClick = e => onClick(e, label);

    return (
        <Item dark open={!isOpen} onClick={onItemClick} style={{cursor: 'pointer'}}>
            {label}
            {isOpen && (
                <Information>
                    {children}
                </Information>
            )}
        </Item>
    );
};

AccordionSection.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default AccordionSection;
