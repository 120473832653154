import React from 'react';
import {Helmet} from "react-helmet";

import Page from "../Page";

import ContactForm from "../../Components/ContactForm/ContactForm";
import AppointmentForm from "../../Components/AppointmentForm/AppointmentForm";

import Block from "../../StyledComponents/Block";
import {Col, Row} from "../../StyledComponents/Grid";
import {Heading, Link, Paragraph} from "../../StyledComponents/Typography";

import {contact as HeroBannerImages} from "../../Assets/HeroBannerImages";

const Contact = () => (
    <>
        <Helmet>
            <title>Contact opnemen - Mobieledierenscan.nl</title>
            <meta charSet="utf-8"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description"
                  content="Heb je een vraag of wilt u meer informatie over de CT-scan en procedure? Dan kunt u contact opnemen met Mobieledierenscan."/>
            <meta name="keywords"
                  content="Contact, Informatie, Bellen, Email, Mobieledierenscan, Kliniek"/>
            <link rel="canonical" href="https://mobieledierenscan.nl/dierenarts/contact"/>
        </Helmet>

        <Page heroBannerImages={HeroBannerImages}>
            <Row flexDirection={['column', 'column', 'column', 'row']} mb="md">
                <Col width={1}>
                    <Block width={1}>
                        <Heading as="h1">Contact</Heading>

                        <Heading as="h2" fontSize="md">Veterinair Mobiele CT-scan</Heading>
                        <Paragraph fontSize="md">
                            <a href="mailto:info@mobieledierenscan.nl">info@mobieledierenscan.nl</a>
                        </Paragraph>

                        <Paragraph>
                            Correspondentie adres:<br/>
                            Ooster Vlaerdinge 73<br/>
                            1704 MX Heerhugowaard<br/>
                        </Paragraph>
                    </Block>
                </Col>
            </Row>
            <Row flexDirection={['column', 'column', 'column', 'row']}>
                <Col width={[1, 1, 1, 2 / 3]} mr={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <Block dark width={1}>
                        <Heading as="h2">Afspraak maken</Heading>
                        <Paragraph>
                            Bent u dierenarts en wilt u een afspraak maken voor uw patiënt, maak dan gebruik van
                            onderstaande formulier.
                        </Paragraph>
                        <AppointmentForm/>
                    </Block>
                </Col>
                <Col width={[1, 1, 1, 2 / 3]} ml={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <Block width={1}>
                        <Heading as="h2">Contact</Heading>
                        <ContactForm isAnimalDoctor/>
                    </Block>
                </Col>
            </Row>
        </Page>
    </>
);

export default Contact;
