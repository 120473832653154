import styled, {css} from "styled-components";

export const BadgeWrapper = styled('div')(props => css`
    position: sticky;
    background-color: ${props.dark ? props.theme.colors.primary : props.theme.colors.background};
    color: ${props.dark ? props.theme.colors.white : props.theme.colors.primary};
    padding: ${props.theme.space.lg};
    top: 200px;

    ${props.theme.shadow.side};
`);
