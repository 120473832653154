import React, {useState} from 'react';
import {Helmet} from "react-helmet";

import Page from "../Page";

import AppointmentForm from "../../Components/AppointmentForm";
import ReadMore from "../../Components/ReadMore";

import Block from "../../StyledComponents/Block";
import {Col, Row} from "../../StyledComponents/Grid";
import {Heading, Paragraph} from "../../StyledComponents/Typography";
import Image from "../../StyledComponents/Image";

import imageNathalie from "../../Assets/Images/nathalie-schigt.png";
import {index as HeroBannerImages} from "../../Assets/HeroBannerImages";
import PersonBadge from "../../Components/PersonBadge";

const Index = () => {
    const [aboutVmctStatus, setAboutVmctStatus] = useState(false);
    const [aboutMeStatus, setAboutMeStatus] = useState(false);

    return (
        <>
            <Helmet>
                <title>CT-Scan voor uw huisdier - Mobieledierenscan.nl</title>
                <meta charSet="utf-8"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description"
                      content="Bij uw eigen dierenarts maken wij een ct-scan van uw hond, kat, vogel of knaagdieren."/>
                <meta name="keywords"
                      content="CT Scan, Onderzoek, Diagnostiek, Hond, Kat, Vogel, Knaagdier, Reptielen, Dierenarts"/>
                <link rel="canonical" href="https://mobieledierenscan.nl/dierenarts"/>
            </Helmet>

            <Page heroBannerImages={HeroBannerImages}>
                <Row flexDirection={['column', 'column', 'column', 'row']}>
                    <Col width={[1, 1, 1, 1 / 2]} mr={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                        <Block width={1} mb="md">
                            <Heading as="h1">Wat is Veterinair Mobiele CT-scan</Heading>
                            <Paragraph>
                                Diagnostiek is een belangrijke stap in de diergeneeskunde. Niemand kan door een dier
                                heen kijken en zeggen wat er aan de hand is. Echter met een CT-scan kun je het dier
                                wel goed in beeld brengen om zo dicht mogelijk bij de diagnose te komen en de
                                eigenaar een realistische prognose en/of therapie bieden. Dit is immers de basis van
                                iedere dierenkliniek.
                            </Paragraph>
                            <Paragraph>
                                Veterinair Mobiele CT-scan (VMS) is een kleine vrachtwagen voorzien van een CT-scan.
                                Door het mobiele aspect kunnen wij bij iedere praktijk een scan uitvoeren. De
                                vrachtwagen is volledig zelfvoorzienend in stroomgebruik.
                            </Paragraph>
                            {aboutVmctStatus === false &&
                            <ReadMore onClick={() => setAboutVmctStatus(true)} status={aboutVmctStatus}/>}
                            {aboutVmctStatus === true && (
                                <>
                                    <Paragraph>
                                        Met röntgen en echografie kunnen al vele diagnoses gesteld worden, echter zijn
                                        deze
                                        apparaten op bot- en weke delen gebied niet sterk genoeg. Hiervoor biedt de
                                        CT-scan
                                        uitkomst. Vaak worden patiënten doorverwezen naar specialistische dierklinieken
                                        met
                                        een CT-scan. Voor de dierenarts betekend dit verlies van de eventueel
                                        aanvullende
                                        behandeling. Voor de eigenaar van het dier zal deze naar de specialistenkliniek
                                        moeten afreizen wat voor een ziek dier niet bevorderlijk is laat staan de
                                        emotionele
                                        last voor de eigenaar.
                                    </Paragraph>
                                    <Paragraph>
                                        Omdat veel praktijkeigenaren naast de financiële ruimte ook niet over de fysieke
                                        ruimte en expertise beschikken een CT-scan aan te schaffen, is het een uitkomst
                                        om
                                        wel een mobiele CT-scan aan de praktijk tot hun beschikking te hebben om toch de
                                        diagnose te kunnen stellen.
                                    </Paragraph>
                                    <ReadMore onClick={() => setAboutVmctStatus(false)} status={aboutVmctStatus}/>
                                </>
                            )}
                        </Block>
                        <Block width={1} mb="md">
                            <Heading as="h1">De CT-scan voor uw praktijk</Heading>
                            <Paragraph>
                                De Mobiele CT-scan wordt bediend door dierenarts Nathalie Leenheer-Schigt. U kunt via
                                deze website, e-mail of via de telefoon (WhatsApp mogelijk) een afspraak inplannen op
                                het gewenste tijdstip. Wanneer VMS bij uw praktijk aankomt wordt de patiënt door u in
                                slaap gebracht en plaatst u een braunule. In de wagen neemt Nathalie de
                                verantwoordelijkheid en anesthesie over en plaatst het dier onder de CT-scan. Indien
                                nodig wordt er een breathhold moment toegepast of wordt er door middel van de braunule
                                contrastvloeistof toegediend. Na de scan wordt het dier weer slapend aan u overgedragen.
                            </Paragraph>
                            <Paragraph>
                                Het beeldmateriaal wordt door onze radioloog beoordeeld en voorzien van een verslag.
                                Voor eventuele therapeutische adviezen kan Nathalie u eveneens bijstaan. Op deze manier
                                hoeven diereigenaren niet meer met het zieke dier de weg op en kunnen zij in de
                                vertrouwde omgeving blijven van uw kliniek, en bij u als eigen dierenarts.
                            </Paragraph>
                        </Block>
                        <Block dark width={1} mb="md">
                            <Heading>Afspraak maken?</Heading>
                            <Paragraph>
                                Bent u dierenarts en wilt u een afspraak maken voor uw patiënt, maak dan gebruik van
                                onderstaande.
                            </Paragraph>
                            <AppointmentForm/>
                        </Block>
                    </Col>
                    <Col width={[1, 1, 1, 1 / 2]} ml={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                        <PersonBadge>
                            <Image
                                float="right"
                                width={['40%', '40%', '20%', '30%']}
                                ml="md"
                                src={imageNathalie}
                            />
                            <Heading as="h1">Wie ben ik en wat doe ik?</Heading>
                            <Paragraph>
                                Nathalie Leenheer-Schigt is in 2011 afgestudeerd als dierenarts in kleine huisdieren
                                aan de Universiteit van Gent, België. Na haar studie is zij begonnen met werken in
                                een eerstelijns praktijk in Rotterdam. Tijdens haar studie heeft ze al een grote
                                voorkeur ontwikkeld voor weke delen chirurgie en orthopedie. In 2013 heeft Nathalie
                                deze vaardigheden in een grote kliniek in Alkmaar verder uitgebreid. Onder het
                                gezelschap van een ervaren collega op gebied van scopie (rhinoscopie, otoscopie,
                                gastroscopie, laparoscopie) heeft zij ook deze vaardigheden en kennis op haar CV
                                erbij mogen schrijven.
                            </Paragraph>
                            <Paragraph>
                                In februari 2018 is Nathalie gaan werken bij een grote kliniek in Middenmeer, waar
                                zij haar orthopedische vaardigheden nog verder heeft ontwikkeld, inclusief de zorg
                                na een operatie. Sinds begin 2019 werkt Nathalie ook op inhuur basis bij verwijskliniek
                                Hugo als orthopedisch chirurg.
                            </Paragraph>
                            {!aboutMeStatus &&
                            <ReadMore onClick={() => setAboutMeStatus(true)} status={aboutMeStatus}/>}
                            {aboutMeStatus && (
                                <>
                                    <Paragraph>
                                        Het blijven groeien en ontwikkelen is één van haar sterke kanten en ondernemen
                                        hoort
                                        daar ook bij. Vandaar dat zij de stap heeft genomen Veterinair Mobiele CT-scan
                                        op te
                                        richten en hierbij de praktijken de gelegenheid te geven te groeien én betere
                                        diagnostiek te kunnen toepassen aan alle patiënten.
                                    </Paragraph>
                                    <Paragraph>
                                        Nathalie heeft het concept bedacht om praktijken te bezoeken met een mobiele
                                        CT-scan. De praktijkeigenaar kan nu deze diagnostiek met eventueel aanvullende
                                        behandeling aanbieden aan zijn/haar klanten zonder aanschaf van een CT-scan.
                                        Hierdoor stijgt de waardering van de praktijk naar meer dan een
                                        eerstelijnskliniek.
                                        Ook de kennis en kunde binnen de kliniek wordt versterkt door het kunnen
                                        aanbieden
                                        van een behandelmethode na de CT-scan. Voorheen werd deze behandeling opgepakt
                                        door
                                        de specialistenkliniek. Voor de eigenaar van het betreffende dier kan hij/zij
                                        bij
                                        zijn/haar vertrouwde dierenarts in de eigen regio blijven.
                                    </Paragraph>
                                    <Paragraph>
                                        In veel gevallen bestaat er geen keuze om een CT-scan wel of niet te doen, het
                                        is op
                                        deze momenten een vereiste in het behandeltraject. In plaats van op basis van
                                        röntgen- of echobeelden een operatie uit te voeren om de oorzaak van het
                                        probleem te
                                        achter halen wordt met de CT-scan direct resultaat behaald zonder een operatie.
                                        Bovendien komt tijdens een operatie slechts 75% van het echografische onderzoek
                                        overeen met de bevindingen tijdens een operatie. 25% van het dier is niet in
                                        beeld
                                        gebracht uit het echografische onderzoek of kon niet dusdanig uit het beeld
                                        opgemaakt worden (Schigt, 2011). Met een CT-scan wordt er een dwarsdoorsnede
                                        gemaakt
                                        van het dier en wordt nagenoeg alles in beeld gebracht. Bovendien kan het in
                                        veel
                                        gevallen kostentechnisch voordeliger zijn een CT-scan te doen dan bijvoorbeeld
                                        een
                                        röntgenonderzoek + aanvullend operatief onderzoek. De operatie na een CT-scan
                                        kan
                                        immers met een hogere precisie uitgevoerd worden, wat minder schade aan het dier
                                        veroorzaakt en waarmee de operatie efficiënter en effectiever uitgevoerd kan
                                        worden.
                                    </Paragraph>
                                    <ReadMore onClick={() => setAboutMeStatus(false)} status={aboutMeStatus}/>
                                </>
                            )}
                        </PersonBadge>
                    </Col>
                </Row>
            </Page>
        </>
    )
};

export default Index;
