import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";

export const FooterWrapper = styled('footer')(props => css`
    background-color: ${props.theme.colors.primary};
    border-top: 1px solid ${props.theme.colors.white};
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: ${props.theme.space.md};
`);

export const FooterContainer = styled('div')(props => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    
    @media ${props.theme.breakpoints.tablet} {
        flex-direction: row;
    }
`);

export const CopyRight = styled('div')(props => css`
    align-self: center;
`);

export const Agreements = styled('div')(props => css`
    align-self: center;
    display: flex;
`);

export const AgreementsLink = styled(NavLink)(props => css`
    color: ${props.theme.colors.white};
    text-decoration: none;
    padding: 0 ${props.theme.space.sm};
    border-left: 1px solid ${props.theme.colors.white};
    
    &:first-of-type {
        border-left: 0;
    }
        
    &:hover {
        text-decoration: underline;
    }
    
    &:visited {
        color: ${props.theme.colors.white};    
    }
`);


export const AgreementsNewLink = styled.a(props => css`
    color: ${props.theme.colors.white};
    text-decoration: none;
    padding: 0 ${props.theme.space.sm};
    border-left: 1px solid ${props.theme.colors.white};
    
    &:first-of-type {
        border-left: 0;
    }
        
    &:hover {
        text-decoration: underline;
    }
    
    &:visited {
        color: ${props.theme.colors.white};    
    }
`);
