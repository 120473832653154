import styled, {css} from "styled-components";

export const NewsletterContainer = styled('div')(props => css`
    padding-bottom: ${props.theme.space.md};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    @media ${props.theme.mediaQueries.laptop} {
        flex-direction: row;
    }
`);

export const Label = styled('label')(props => css`
    ${props.theme.fonts.light};

    align-self: center;
    font-size: 18px;
    text-align: center;
    margin-bottom: ${props.theme.space.md};
    
    a {
        color: ${props.theme.colors.white};
    }
`);
