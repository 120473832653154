import React from 'react';

import PropTypes from "prop-types";
import {BadgeWrapper} from "./style";

const PersonBadge = (props) => (
    <BadgeWrapper {...props}>
        {props.children}
    </BadgeWrapper>
);

PersonBadge.propTypes = {
    children: PropTypes.node,
};

PersonBadge.defaultProps = {
    children: null,
};

export default PersonBadge;
