import styled, {css} from "styled-components";
import {space, width} from "styled-system";

export const Label = styled('div')(props => css`
    align-self: center;
    font-size: ${props.theme.fontSize.rg};
    padding-bottom: ${props.theme.space.xs};
    position: relative;

    ${props.required && css`
        &:after {
            content: '*';
            position: absolute;
            font-size: ${props.theme.fontSize.md};
            top: -1px;
            right: ${props.theme.space.sm};
        }
    `}

    ${space}
    ${width}
`);
