import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";

import Container from "../../StyledComponents/Container";
import LogoHeader from "../../StyledComponents/LogoHeader";
import Menu from "../Menu";

import {HeaderWrapper} from "./style";

const Header = (props) => (
    <HeaderWrapper>
        <Container justifyContent="space-between" flexDirection="row">
            <NavLink to="/" onClick={props.resetState}>
                <LogoHeader size="xxxl"/>
            </NavLink>
            <Menu/>
        </Container>
    </HeaderWrapper>
);

Header.propTypes = {
    resetState: PropTypes.func.isRequired,
};

export default Header;
