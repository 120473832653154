import React from 'react';

import Container from "../../StyledComponents/Container";

import {ServiceReminderWrapper} from "./style";
import PropTypes from "prop-types";
import {Link} from "../../StyledComponents/Typography";

const ServiceReminder = (props) => (
    <ServiceReminderWrapper {...props}>
        <Container color="white" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
        </Container>
    </ServiceReminderWrapper>
);

ServiceReminder.propTypes = {
    position: PropTypes.string,
};

ServiceReminder.defaultProps = {
    position: null,
};

export default ServiceReminder;
