import styled, {css} from "styled-components";
import {space} from "styled-system";

export const List = styled('ul')(props => css`
    font-size: ${props.theme.fontSize[props.fontSize] || props.fontSize || props.theme.fontSize.rg};
    margin-bottom: ${props.theme.space.md};
    
    ${space}
`);

export const Item = styled('li')(props => css`
    position: relative;
    width: 100%;
    list-style: none;
    clear: both;
    padding: 2px 0;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;

    &:before {
        content: '';
        margin-right: 10px;
        width: 5px;
        height: 5px;
        display: inline-flex;
        background-color: ${props.theme.colors.primary};
        align-items: center;
    }
`);
