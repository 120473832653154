export function setOwner() {
    return {type: 'SET_OWNER' };
}

export function setVeterinarian() {
    return {type: 'SET_VETERINARIAN' };
}

export function resetState() {
    return {type: 'RESET_STATE'};
}

export function setLoading(status = true) {
    return {type: 'SET_LOADING', status};
}
