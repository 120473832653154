import React from 'react';
import styled, {css} from 'styled-components';

import images from "../Theme/images";

const Logo = props => <img {...props} src={images.logo} alt="VMCT"/>;

export default styled(Logo)(props => css`
    height: auto;
    width: ${props.theme.size[props.width] || props.width || '100%'};
    max-width: 1000px;
`);
