import styled, {css} from "styled-components";
import {flexDirection, space, width} from "styled-system";

export const Input = styled('input')(props => css`
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid ${props.white ? props.theme.colors.white : props.theme.colors.primary};
    padding: ${props.theme.space.md};
    color: ${props.white ? props.theme.colors.white : props.theme.colors.primary};

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: ${props.theme.colors.grey};
        border-color: ${props.theme.colors.grey};
    }

    ${space}
    ${width}
`);

export const TextArea = styled('textarea')(props => css`
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid ${props.theme.colors.primary};
    padding: ${props.theme.space.md};
    min-height: ${props.theme.size.lg};

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: ${props.theme.colors.grey};
        border-color: ${props.theme.colors.grey};
    }

    ${space}
    ${width}
`);

const buttonTemplate = css`
    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: ${props => props.theme.colors.grey};
        border-color: ${props => props.theme.colors.primary};
    }

    ${space}
    ${width}
`;

export const SubmitButton = styled('button')(props => css`
    background: ${props.theme.colors.secondaryLighter};
    border: 1px solid ${props.theme.colors.secondaryLighter};
    color: ${props.theme.colors.primary};
    font-weight: bold;
    height: 42px;
    width: 100%;

    ${buttonTemplate}
`);

export const SendButton = styled('button')(props => css`
    background: transparent url(${props.theme.images.send}) no-repeat left center;
    background-size: 75%;
    border: 0;
    border-bottom: 1px solid white;
    cursor: pointer;
    font-size: 0;
    height: 57px;
    line-height: 0;
    width: ${props.theme.space.xxl};
    color: white;

    ${buttonTemplate}
`);

export const Form = styled('div')(props => css`
    display: flex;
    align-items: center;

    ${flexDirection}
`);
