import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";


export const MenuWrapper = styled('div')(props => css`
    align-self: center;
    display: flex;
    margin: 0;
    padding: 0;
`);

export const Hamburger = styled('button')(props => css`
    background: transparent url(${props.theme.images.hamburger}) no-repeat center center;
    background-size: contain;
    border: 0;
    cursor: pointer;
    display: block;
    height: ${props.theme.space.lg};
    width: ${props.theme.space.lg};

    &:focus {
        outline: none;
    }
    
    @media ${props.theme.mediaQueries.tablet} {
        display: none;
    }
`);

export const InnerMenu = styled('div')(props => css`
    align-self: center;
    background-color: ${props.theme.colors.secondaryLighter};
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    position: fixed;
    left: -1px;
    transition: left 500ms ease-in-out;
    top: 100px;
    width: 100%;
    z-index: 4;

    ${props.isOpen === false && css`
        left: 100%;
    `}
    
    @media ${props.theme.mediaQueries.tablet} {
        background-color: transparent;
        flex-direction: row;
        height: auto;
        left: 0;
        position: relative;
        top: 0;
    }
`);

export const ListItem = styled(NavLink)(props => css`
    font-size: ${props.theme.fontSize.md};
    padding: ${props.theme.space.sm} ${props.theme.space.md};
    text-decoration: none;
    position: relative;

    &:first-of-type {
        margin-top: ${props.theme.space.sm};
    }

    @media ${props.theme.mediaQueries.tablet} {
        &:first-of-type {
            margin-top: 0;
        }
    }

    &.active, &:hover {
        color: ${props.theme.colors.primary};

        &::after {
            content: '';
            background-color: transparent;
            position: absolute;
            top: 0;
            left: calc(${props.theme.space.md} / 2);
            height: 100%;
            display: flex;
            margin-top: 0;
            width: calc(100% - ${props.theme.space.md});
            border-radius: ${props.theme.space.xs};
            z-index: -1;

            @media ${props.theme.mediaQueries.tablet} {
                background-color: transparent;
                border-radius: 0;
                left: 0;
                width: 100%;
            }
        }
    }
    
    &:hover {
        &::after {
            border-bottom: 1px solid ${props.theme.colors.primary};
        }
    }
`);
