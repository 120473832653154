import colors from './colors';
import fonts from './fonts';
import fontSize from "./fontSize";
import space from './space';
import images from "./images";
import shadow from "./shadow";
import size from "./size";
import {breakpoints, mediaQueries} from "./mediaQueries";

import GlobalStyling from './GlobalStyling';

export default {
    space,
    colors,
    fonts,
    fontSize,
    images,
    shadow,
    size,
    breakpoints,
    mediaQueries,
    GlobalStyling,
};
