import styled, {css} from 'styled-components';
import {
    color,
    display,
    fontSize,
    fontStyle,
    fontWeight,
    maxWidth,
    space,
    style,
    textAlign,
    textStyle,
    width,
} from 'styled-system';

const textDecoration = style({
    prop: 'textDecoration',
    cssProperty: 'textDecoration',
    key: 'textDecoration',
});

const fontAttributes = [
    space,
    display,
    fontSize,
    fontWeight,
    width,
    textAlign,
    maxWidth,
    color,
    fontStyle,
    textStyle,
    textDecoration
];

export const Heading = styled('h1')(props => css`
    font-size: ${props.theme.fontSize[props.fontSize] || props.fontSize || props.theme.fontSize.lg};
    font-weight: lighter;
    line-height: auto;
    margin-bottom: ${props.theme.space.xs};
    letter-spacing: 0.5px;
    color: ${props.theme.colors[props.color || 'primary']};

    ${props.frontPage && css`
        ${props.theme.fonts.trajan};
        font-size: ${props.theme.fontSize.xl};
    `};
`, fontAttributes);

export const Paragraph = styled.p(props => css`
    font-size: ${props.theme.fontSize[props.fontSize] || props.fontSize || props.theme.fontSize.rg};
    line-height: ${props.lh || '1.4em'};
    margin-bottom: ${props.theme.space.md};
    color: ${props.theme.colors[props.color || 'text']};

    &:last-child {
        margin-bottom: 0;
    }
`, fontAttributes);

export const Link = styled('span')(props => css`
    color: ${props.theme.colors[props.color || 'white']};
    text-decoration: none;

    &:visited {
        color: ${props.theme.colors[props.color || 'white']};
    }

    &.active::after {
        content: '';
        border-top: 1px solid ${props.theme.colors[props.color || 'white']};
        height: 0;
        display: flex;
        margin-top: 1px;
    }
`, fontAttributes);
