import React, {useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {animalDoctorItems, animalOwnerItems} from "../../Config/menuItems";

import {Hamburger, InnerMenu, ListItem, MenuWrapper} from "./style";

const Menu = ({onVeterinarian, onOwner}) => {
    const [openState, setOpenState] = useState(false);

    const menuItems = onOwner
        ? animalOwnerItems
        : (onVeterinarian
                ? animalDoctorItems
                : []
        );

    return (
        <MenuWrapper>
            <Hamburger isOpen={openState} onClick={() => setOpenState(!openState)}/>
            <InnerMenu isOpen={openState}>
                {menuItems.map(item => (
                    <ListItem
                        key={item.label}
                        onClick={() => setOpenState(!openState)}
                        to={item.to}
                    >
                        {item.label}
                    </ListItem>
                ))}
            </InnerMenu>
        </MenuWrapper>
    );
};

Menu.propTypes = {
    onVeterinarian: PropTypes.bool.isRequired,
    onOwner: PropTypes.bool.isRequired,
};

export default connect(
    state => ({
        onOwner: state.get('onOwner'),
        onVeterinarian: state.get('onVeterinarian'),
    }),
)(Menu);
