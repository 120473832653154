const numericBreakPoints = [
    420,
    768,
    1024,
    1200,
];

// This constant is used by styled system
export const breakpoints = numericBreakPoints.map(bp => `${bp}px`);

export const mediaQueries = {
    maxMobile: `only screen and (max-width: ${breakpoints[0]})`,
    mobile: `only screen and (min-width: ${breakpoints[0]})`,
    maxTablet: `only screen and (max-width: ${breakpoints[1] - 1})`,
    tablet: `only screen and (min-width: ${breakpoints[1]})`,
    laptop: `only screen and (min-width: ${breakpoints[2]})`,
    desktop: `only screen and (min-width: ${breakpoints[3]})`,
};
