import styled, {css} from "styled-components";

export const HeaderWrapper = styled('header')(props => css`
    background-color: ${props.theme.colors.white};
    display: flex;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;
    padding: ${props.theme.space.md};

    ${props.theme.shadow.straight};
`);
