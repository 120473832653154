import styled, {css} from "styled-components";
import {height, minHeight, space} from "styled-system";

export default styled('article')(props => css`
    display: flex;
    flex-direction: column;
    padding: ${props.theme.space.md};
    color: ${props.theme.colors.primary};
    margin-bottom: ${props.theme.space.xl};

    &:after {
        content: '';
        clear: both;
        display: block;
    }

    @media ${props.theme.mediaQueries.laptop} {
        padding: ${props.theme.space.md};
    }
`, [height, minHeight, space]);
