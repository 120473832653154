import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

import Page from "../Page";

import ContactForm from "../../Components/ContactForm/ContactForm";

import Block from "../../StyledComponents/Block";
import {Col, Row} from "../../StyledComponents/Grid";
import {Heading, Paragraph} from "../../StyledComponents/Typography";

import {contact as HeroBannerImages} from "../../Assets/HeroBannerImages";

const Contact = () => (
    <>
        <Helmet>
            <title>Contact opnemen - Mobieledierenscan.nl</title>
            <meta charSet="utf-8"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description"
                  content="Heb je een vraag of wilt u meer informatie over de CT-scan en procedure? Dan kunt u contact opnemen met Mobieledierenscan. Het algemene telefoonnumer is 06 82 29 55 77"/>
            <meta name="keywords"
                  content="Contact, Informatie, Bellen, Email, Mobieledierenscan, Kliniek"/>
            <link rel="canonical" href="https://mobieledierenscan.nl/diereneigenaar/contact"/>
        </Helmet>

        <Page heroBannerImages={HeroBannerImages}>
            <Row flexDirection={['column', 'column', 'column', 'row']}>
                <Col width={1} ml={[0, 0, 0, 'sm']} my={['sm', 'sm', 'sm', 0]}>
                    <Block width={1}>
                        <Heading as="h2">Contact</Heading>
                        <Paragraph>
                            Wilt u graag meer informatie ontvangen? Vul onderstaand formulier in om contact op
                            te nemen.
                        </Paragraph>
                        <ContactForm isAnimalOwner/>
                    </Block>
                </Col>
            </Row>
        </Page>
    </>
);

Contact.propTypes = {
    page: PropTypes.object,
};

Contact.defaultProps = {
    page: {},
};

export default Contact;
