import styled, {css} from "styled-components";
import {alignItems, display, flexDirection, justifyContent, space} from "styled-system";

export default styled('div')(props => css`
    align-items: flex-start;
    background: ${props.whiteBackground ? 'rgba(255,255,255,0.85)' : 'transparent'};
    color: ${props.theme.colors[props.color] || props.theme.colors.primary};
    display: flex;
    font-weight: 100;
    max-width: 1440px;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
`, space, justifyContent, flexDirection, display, alignItems);
