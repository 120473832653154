import styled, {css} from "styled-components";
import Container from "../../StyledComponents/Container";

export const ServiceReminderWrapper = styled('div')(props => css`
    background-color: ${props.theme.colors.primary};
    font-size: ${props.theme.fontSize.xs};
    top: ${props.top >= 0 ? props.top : '103px'};
    height: 75px;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    position: ${props.position || 'sticky'};
    z-index: 1;

    @media ${props.theme.mediaQueries.laptop} {
        font-size: ${props.theme.fontSize.lg};
    }
    
    ${Container} {
        background-color: ${props.theme.colors.primary};
    }
`);
