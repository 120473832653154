import styled, {css} from "styled-components";

export default styled('div')(props => css`
    display: inline-block;
    width: 100%;
    height: 100%;
    background: url(${props.url}) ${props.repeat ? 'repeat' : 'no-repeat'} center;
    background-size: ${!props.repeat ? 'cover' : 'auto'};
    position: relative;
`);
