import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {resetState, setOwner, setVeterinarian} from "../Store/Actions/app";

import Video from "../Components/Video";
import Selection from "../Components/Selection";
import ServiceReminder from "../Components/ServiceReminder";

import Container from "../StyledComponents/Container";
import PropTypes from "prop-types";

const Index = (props) => {
    useEffect(() => {
        props.resetState();
    }, []);

    return (
        <>
            <Helmet>
                <title>CT-Scan voor uw huisdier – Mobieledierenscan.nl</title>
                <meta charSet="utf-8"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description"
                      content="Bij uw eigen dierenarts maken wij een ct-scan van uw hond, kat, vogel of knaagdieren."/>
                <meta name="keywords"
                      content="CT Scan, Onderzoek, Diagnostiek, Hond, Kat, Vogel, Knaagdier, Reptielen, Dierenarts"/>
                <link rel="canonical" href="https://mobieledierenscan.nl/"/>
            </Helmet>
            <Video
                src="https://www.youtube.com/embed/kRi454wct_w"
                justifyContent={['flex-end', 'flex-end', 'flex-end', 'center']}
                alignItems="center"
                flexDirection="column"
            >
                <ServiceReminder position="fixed" top={0}/>
                <Container px="md">
                    <Selection setOwner={props.setOwner} setVeterinarian={props.setVeterinarian}/>
                </Container>
            </Video>
        </>
    );
};

Index.propTypes = {
    setOwner: PropTypes.func.isRequired,
    setVeterinarian: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
};

export default connect(
    state => ({}),
    dispatch => bindActionCreators({
        setOwner,
        setVeterinarian,
        resetState,
    }, dispatch),
)(Index);
