import styled, {css} from "styled-components";
import {space, alignContent, alignItems, justifyContent, display, fontSize, flexDirection} from "styled-system";

export const VideoWrapper = styled('div')(() => css`    
    @media (min-aspect-ratio: 16/9) {
        ${VideoForeground} { height: 300%; top: -100%; }
    }
    @media (max-aspect-ratio: 16/9) {
        ${VideoForeground} { width: 300%; left: -100%; }
    }
    @media all and (max-width: 600px) {
        .vid-info { width: 50%; padding: .5rem; }
        .vid-info h1 { margin-bottom: .2rem; }
    }
    @media all and (max-width: 500px) {
        .vid-info .acronym { display: none; }
    }
`);

export const VideoBackground = styled('div')((props) => css`
    background: ${props.theme.colors.white};
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -99;
`);

export const VideoForeground = styled('div')((props) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    
    iframe {
        position: absolute;
        top: 0;
        left: -9%;
        width: 120%;
        height: 100%;
        pointer-events: none;
        
        @media ${props.theme.mediaQueries.mobile} {
           width: 100%;
           left: 0;
        }
    }
`);

export const Cover = styled('div')(() => css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    max-height: 100vh;
    background-color: rgba(255,255,255,.25);
    display: flex;
    flex-direction: column:
`, [space, alignContent, alignItems, justifyContent, display, fontSize, flexDirection]);


