import styled, {css} from "styled-components";

export const List = styled('ul')(props => css`
    min-height: ${props.minHeight === 0 ? 0 : '500px'};
    overflow: auto;
`);

export const Information = styled('div')(props => css`
    display: block;
    width: 100%;
    font-weight: normal;
    padding: ${props.theme.space.sm} 0 0;
`);

export const Item = styled('li')(props => css`
    position: relative;
    width: 100%;
    list-style: none;
    clear: both;
    padding: ${props.theme.space.sm} ${props.theme.space.md};

    ${!props.open && css`
        background-color: ${props.theme.colors.secondaryLighter};
        font-weight: bold;
    `}

    &:before {
        content: '∎';
        padding-right: 10px;
    }

    &:after {
        position: absolute;
        right:  ${props.theme.space.md};
        top: ${props.theme.space.sm};
        
        ${props.open ? css`
            content: '▼';
        ` : css`
            content: '▲';
        `};
        width: auto;
        height: auto;
    }
`);
