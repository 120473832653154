import styled, {css} from 'styled-components';
import {rgba} from "polished";

export const Wrapper = styled.div(props => css`
    position: absolute;
    bottom: 5%;
    width: 100%;
    z-index: 0;
    padding: ${props.theme.space.md} ${props.theme.space.xxxl} 0;
    
    @media ${props.theme.mediaQueries.tablet} {
        bottom: 20%;
    }
    
    @media ${props.theme.mediaQueries.laptop} {
        bottom: 20%;
        left: 10vh;
        max-width: 900px;
    }
    
    @media ${props.theme.mediaQueries.desktop} {
        bottom: 30%;
        left: 10vh;
        max-width: 1000px;
    }
`);

const fontAttributes = props => css`
    line-height: 43px;
    font-size: ${props.theme.fontSize.rg};
    
    @media ${props.theme.mediaQueries.laptop} {
        line-height: 60px;
        font-size: ${props.theme.fontSize.md};
    }
    
     @media ${props.theme.mediaQueries.desktop} {
        font-size: ${props.theme.fontSize.lg};
    }
`;

export const Author = styled.span(props => css`
    background-color: ${rgba(props.theme.colors.secondary, 0.8)};
    color: ${props.theme.colors.white};
    padding: ${props.theme.space.sm};
    white-space: normal;
    
    ${fontAttributes(props)}
`);

export const Content = styled.span(props => css`
    background-color: ${rgba(props.theme.colors.primary, 0.8)};
    color: ${props.theme.colors.white};
    padding: ${props.theme.space.sm};
    white-space: normal;
    
    ${fontAttributes(props)}
`);
