import styled from 'styled-components';
import {
    space,
    color,
    width,
    height,
    position,
    display,
    overflow,
    flexWrap,
    flexDirection,
    alignItems,
    justifyContent,
    flex,
    order,
    alignSelf,
    textAlign,
} from 'styled-system';

export const Col = styled('div')(
    {
        boxSizing: 'border-box',
    },
    space,
    display,
    color,
    width,
    height,
    position,
    overflow,
    flex,
    order,
    alignSelf,
    textAlign,
    props => props.css,
);

Col.displayName = 'Col';

Col.propTypes = {
    ...space.propTypes,
    ...color.propTypes,
    ...width.propTypes,
    ...position.propTypes,
    ...height.propTypes,
};

export const Row = styled(Col)(
    {
        display: 'flex',
        width: '100%',
    },
    height,
    flexWrap,
    flexDirection,
    alignItems,
    justifyContent,
);

Row.displayName = 'Row';

Row.propTypes = {
    ...flexWrap.propTypes,
    ...flexDirection.propTypes,
    ...alignItems.propTypes,
    ...height.propTypes,
    ...justifyContent.propTypes,
};
