import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Container from "../../StyledComponents/Container";

import {Agreements, AgreementsLink, AgreementsNewLink, CopyRight, FooterContainer, FooterWrapper} from "./style";
import {setOwner, setVeterinarian} from "../../Store/Actions/app";
import {bindActionCreators} from "redux";

const Footer = (props) => {
    return (
        <FooterWrapper>
            <Container color="white" display="flex">
                {props.children}
                <FooterContainer>
                    <CopyRight>Veterinair Mobiele CT-scan © 2019</CopyRight>
                    <Agreements>
                        <AgreementsNewLink href={props.onOwner ? '/diereneigenaar/algemene-voorwaarden' : '/dierenarts/algemene-voorwaarden'}>
                            Algemene voorwaarden
                        </AgreementsNewLink>
                        <AgreementsNewLink href={props.onOwner ? '/diereneigenaar/privacy-statement' : '/dierenarts/privacy-statement'}>
                            Privacy Statement
                        </AgreementsNewLink>
                        {props.onVeterinarian && (
                            <AgreementsLink to="/diereneigenaar" onClick={props.setOwner}>
                                Ik ben diereneigenaar
                            </AgreementsLink>
                        )}
                        {props.onOwner && (
                            <AgreementsLink to="/dierenarts" onClick={props.setVeterinarian}>
                                Ik ben dierenarts
                            </AgreementsLink>
                        )}
                    </Agreements>
                </FooterContainer>
            </Container>
        </FooterWrapper>
    )
};


Footer.propTypes = {
    onOwner: PropTypes.bool.isRequired,
    onVeterinarian: PropTypes.bool.isRequired,
    setOwner: PropTypes.func.isRequired,
    setVeterinarian: PropTypes.func.isRequired,

    children: PropTypes.oneOfType([
        PropTypes.node
    ]),
};

Footer.defaultProps = {
    children: [],
};

export default connect(
    state => ({
        onOwner: state.get('onOwner'),
        onVeterinarian: state.get('onVeterinarian'),
    }),
    dispatch => bindActionCreators({
        setOwner,
        setVeterinarian,
    }, dispatch)
)(Footer);
