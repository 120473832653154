import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ReactGA from "react-ga";
import {Route, withRouter} from "react-router-dom";
import {hot} from "react-hot-loader";
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';

import {resetState, setOwner, setVeterinarian} from "./Store/Actions/app";

import Header from "./Components/Header";
import Newsletter from "./Components/Newsletter";
import Footer from "./Components/Footer";

import Index from "./Pages/Index";
import IndexVeterinarian from "./Pages/Veterinarian/Index";
import AboutUsVeterinarian from "./Pages/Veterinarian/AboutUs";
import CTScanVeterinarian from "./Pages/Veterinarian/CTScan";
import LocationsVeterinarian from "./Pages/Veterinarian/Locations";
import ContactVeterinarian from "./Pages/Veterinarian/Contact";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyStatement from "./Pages/PrivacyStatement";

import IndexOwner from "./Pages/Owner/Index";
import CTScanOwner from "./Pages/Owner/CTScan";
import LocationsOwner from "./Pages/Owner/Locations";
import ContactOwner from "./Pages/Owner/Contact";

const App = ({onOwner, onVeterinarian, ...props}) => {
    ReactGA.initialize('UA-132747322-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    library.add(fas);

    useEffect(() => {
        window.location.href.indexOf('diereneigenaar') > -1 && !onOwner && props.setOwner();
        window.location.href.indexOf('dierenarts') > -1 && !onVeterinarian && props.setVeterinarian();
    }, []);

    return (
        <>
            {(onOwner || onVeterinarian) && <Header resetState={props.resetState}/>}

            <Route exact path="/" component={Index}/>
            <Route exact path="/diereneigenaar" component={IndexOwner}/>
            <Route exact path="/diereneigenaar/ct-scan" component={CTScanOwner}/>
            <Route exact path="/diereneigenaar/locaties" component={LocationsOwner}/>
            <Route exact path="/diereneigenaar/contact" component={ContactOwner}/>


            <Route exact path="/dierenarts/algemene-voorwaarden" component={TermsAndConditions}/>
            <Route exact path="/dierenarts/privacy-statement" component={PrivacyStatement}/>

            <Route exact path="/dierenarts" component={IndexVeterinarian}/>
            <Route exact path="/dierenarts/over-ons" component={AboutUsVeterinarian}/>
            <Route exact path="/dierenarts/ct-scan" component={CTScanVeterinarian}/>
            <Route exact path="/dierenarts/locaties" component={LocationsVeterinarian}/>
            <Route exact path="/dierenarts/contact" component={ContactVeterinarian}/>

            {(onOwner || onVeterinarian) && (
                <Footer>
                    <Newsletter/>
                </Footer>
            )}
        </>
    );
};

export default hot(module)(connect(
    state => ({
        loading: state.get('loading'),
        onOwner: state.get('onOwner'),
        onVeterinarian: state.get('onVeterinarian'),
    }),
    dispatch => bindActionCreators({
        setOwner,
        setVeterinarian,
        resetState,
    }, dispatch),
)(withRouter(App)));
